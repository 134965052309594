import { CameraModel } from './camera.model';
export class BunchModel {
    id: number;
    name: string;
    listCamera: CameraModel[];
    dateCreated: string;
    parentId: number;
    bunch:any;
    listChild : any[] = [];
    isActive: boolean = false;
    isClick: boolean = false;
    level: any = 0;
    indexParent: any;
    displayIndex: any;
    resolution:string;
    checked: boolean = true;
}
