<div class="container-fluid p-0">
    <app-header></app-header>
    <div class="page-container page-user">
        <div class="container">
            <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-4" style="padding-right: 15px;">
                    <div class="area-statistic">
                        <h3>Camera</h3>
                        <canvas  id="cameraChart" style="cursor: pointer;">{{ cameraChart }}</canvas>
                        <div class="text-title">{{infoChart.totalConnected + infoChart.totalDisconnected}}</div>
                        <div class="text-label">
                            <div class="blue-status"></div>
                            <span>{{infoChart.totalConnected}} {{'ad_connected_CC'|translate}}</span>
                        </div>
                        <div class="text-label">
                            <div class="gray-status"></div><span>{{infoChart.totalDisconnected}} {{'ad_not_connected_CC'|translate}}</span>
                        </div>
                        <div class="col-md-12 row" style="margin-top: 10px;">
                            <div class="col-md-8 text-note" *ngIf="minute==0 && hours==0">{{'ad_update_final'|translate}}{{second}} {{'ad_last_second'|translate}}</div>
                            <div class="col-md-8 text-note" *ngIf="minute!==0 && hours==0">{{'ad_update_final'|translate}}{{minute}} {{'ad_last_minute'|translate}}</div>
                            <div class="col-md-8 text-note" *ngIf="hours!==0">{{'ad_update_final'|translate}}{{minute}} {{'ad_last_minute'|translate}}</div>
                            <div class="col-md-4 text-right text-note textnote-click" routerLink="/statis-camdisconnect"
                                routerLinkActive="router-link-active">{{'ad_view_detail'|translate}}</div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4" style="padding-right: 15px;">
                    <div class="area-statistic">
                        <h3>{{'ad_package'|translate}}</h3>
                        <canvas  id="packageChart" style="cursor: pointer;">{{ packageChart }}</canvas>
                        <div class="text-title">{{infoChart.totalHavePackage}}</div>
                        <div class="text-label">
                            <div class="blue-status"></div>
                            <span>{{infoChart.totalHavePackage}} {{'ad_camera_bought'|translate}}</span>
                        </div>
                        <div class="text-label">
                            <div class="gray-status"></div><span>{{infoChart.totalNoHavePackage}} {{'ad_camera_no_bought'|translate}}</span>
                        </div>
                        <div class="col-md-12 row" style="margin-top: 10px;">
                            <div class="col-md-8 text-note" *ngIf="minute==0 && hours==0">{{'ad_update_final'|translate}}{{second}}{{'ad_last_second'|translate}}</div>
                            <div class="col-md-8 text-note" *ngIf="minute!==0 && hours==0">{{'ad_update_final'|translate}}{{minute}}{{'ad_last_minute'|translate}}</div>
                            <div class="col-md-8 text-note" *ngIf="hours!==0">{{'ad_update_final'|translate}}{{minute}}{{'ad_last_minute'|translate}}
                            </div>
                            <div class="col-md-4 text-right text-note textnote-click" routerLink="/package">{{'ad_view_detail'|translate}}</div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4">
                    <div class="area-statistic">
                        <h3>Sub-account</h3>
                        <canvas  id="accountChart" style="cursor: pointer;">{{ accountChart }}</canvas>
                        <div class="text-title">{{infoChart.subAccountActive + infoChart.subAccountDeactivate}}</div>
                        <div class="text-label">
                            <div class="blue-status"></div>
                            <span>{{infoChart.subAccountActive}} {{'ad_sub_active'|translate}}</span>
                        </div>
                        <div class="text-label">
                            <div class="gray-status"></div><span>{{infoChart.subAccountDeactivate}} {{'ad_sub_none_active'|translate}}</span>
                        </div>
                        <div class="col-md-12 row" style="margin-top: 10px;">
                            <div class="col-md-8 text-note" *ngIf="minute==0 && hours==0">{{'ad_update_final'|translate}}{{second}}{{'ad_last_second'|translate}}</div>
                            <div class="col-md-8 text-note" *ngIf="minute!==0 && hours==0">{{'ad_update_final'|translate}}{{minute}}{{'ad_last_minute'|translate}}</div>
                            <div class="col-md-8 text-note" *ngIf="hours!==0">{{'ad_update_final'|translate}}{{minute}} {{'ad_last_minute'|translate}}
                            </div>
                            <div class="col-md-4 text-right text-note textnote-click" routerLink="/account">{{'ad_view_detail'|translate}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="area-chart">
                <div class="row">
                    <h3 class="">{{'ad_cam_dis_time'|translate}}</h3>
                    <button class="btn btn-update" (click)="GetStatisticChart()">
                        <i class="ic-update"></i>
                        <span>{{'ad_update'|translate}}</span></button>
                </div>
                <select id="slTimeType" class="border-control" style="padding: 0.375rem 1rem; margin-bottom: 16px;"
                    (change)="GetStatisticChart()">
                    <option value="1">
                        1 {{'ad_last_hour'|translate}}
                    </option>
                    <option value="2">
                        6 {{'ad_last_hour'|translate}}
                    </option>
                    <option value="3" selected>
                        12 {{'ad_last_hour'|translate}}
                    </option>
                    <option value="4">
                        1 {{'ad_last_day'|translate}}
                    </option>
                    <option value="5">
                        3 {{'ad_last_day'|translate}}
                    </option>
                    <option value="6">
                        7 {{'ad_last_day'|translate}}
                    </option>
                    <option value="7">
                        30 {{'ad_last_day'|translate}}
                    </option>
                </select>
                <div class="text-label">
                    <div class="blue-status"></div><span>{{'ad_total_cam'|translate}}</span>
                </div>
                <div class="text-label" style="margin-bottom: 10px">
                    <div class="red-status"></div>
                    <span>{{'ad_cam_dis'|translate}}</span>
                </div>

                <canvas id="connectCamChart" style="cursor: pointer;">{{ chart }}</canvas>
            </div>
            <div class="area-chart">
                <div class="row">
                    <h3>10 {{'ad_top_dis'|translate}}</h3>
                    <button class="btn btn-update" (click)="GetTopDisconnect()">
                        <i class="ic-update"></i>
                        <span>{{'ad_update'|translate}}</span></button>
                </div>
                <select id="slTimeType2" class="border-control" style="padding: 0.375rem 1rem; margin-bottom: 16px;"
                    (change)="GetTopDisconnect()">
                    <option value="1">
                        1 {{'ad_last_hour'|translate}}
                    </option>
                    <option value="2">
                        6 {{'ad_last_hour'|translate}}
                    </option>
                    <option value="3">
                        12 {{'ad_last_hour'|translate}}
                    </option>
                    <option value="4">
                        1 {{'ad_last_day'|translate}}
                    </option>
                    <option value="5" selected>
                        3 {{'ad_last_day'|translate}}
                    </option>
                    <option value="6">
                        7 {{'ad_last_day'|translate}}
                    </option>
                    <option value="7">
                        30 {{'ad_last_day'|translate}}
                    </option>
                </select>
                <canvas id="topDisConnectChart">{{topChart}}</canvas>
            </div>
            <div class="area-chart" *ngIf="currDisConect.name && isViewTable">
                <div class="row">
                    <h3>Camera <span style="color:#ee0033">{{currDisConect.name}}</span></h3>
                    <button type="button" aria-label="Close" class="close" style="background: #D9D9D9; border-radius: 50%; width: 30px;;" (click)="CloseStatistic()" title="{{'ad_close'|translate}}">
                        <i class="icon icon-close" style="margin-top: 2px"></i>
                    </button>
                </div>
                <h3>{{'ad_statistic_CC'|translate}} (<span style="color: #ee0033;">{{currDisConect.number}}</span>)
                </h3>
                <div class="page-table">
                    <div class="table-responsive">
                        <table class="table table-striped user-table">
                            <thead>
                                <tr class="header-table" style="padding:15px 0px 15px 0px; text-align: center;">
                                    <th scope="col">{{'ad_stt'|translate}}</th>
                                    <th scope="col">{{'ad_start_time'|translate}}</th>
                                    <th scope="col">{{'ad_end_time'|translate}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style="padding:15px 0px 15px 0px; text-align: center;"
                                    *ngFor="let dis of currDisConect.detail|slice:0:page; let i= index;">
                                    <td>{{i+1}}</td>
                                    <td>{{dis.timeStart | date : "HH:mm dd/MM/y"}}</td>
                                    <td>{{dis.timeEnd | date : "HH:mm dd/MM/y"}}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div *ngIf="page<currDisConect.detail.length" (click)="ReadMore()" class="text-link">{{'ad_read_more'|translate}}
                        </div>
                        <div *ngIf="page==currDisConect.detail.length && isReadMore" (click)="HideOff()"
                            class="text-link">{{'ad_hide'|translate}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer class="idc-footer">
        <p class="copyright">Copyright © 2015 - 2024, Công ty TNHH Viettel - CHT</p>
    </footer>