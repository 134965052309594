import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../_services/authentication.service';
declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  constructor(public router: Router, public authenticationSevice: AuthenticationService) {
  }

  ngOnInit(): void {
    if ($(".blocks-aboutus").length) {
      $(".blocks-aboutus").slick({
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              dots: true,
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true,
            }
          }
        ]
      });
    }

    if ($(".block-partners").length) {
      $(".block-partners").slick({
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        arrows: false,
        dots: true,
        autoplay: true,
        autoplaySpeed: 5000,
        responsive: [
          {
            breakpoint: 1025,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 5,
              dots: true,
            }
          },
          {
            breakpoint: 769,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              dots: true,
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              dots: true,
            }
          }
        ]
      });
    }
    // if ($(".block-prices").length) {
    //   $(".block-prices").slick({
    //     infinite: false,
    //     speed: 500,
    //     slidesToShow: 4,
    //     slidesToScroll: 1,
    //     responsive: [
    //       {
    //         breakpoint: 1025,
    //         settings: {
    //           slidesToShow: 3,
    //           slidesToScroll: 1,
    //           dots: true,
    //           arrows: false,
    //         }
    //       },
    //       {
    //         breakpoint: 769,
    //         settings: {
    //           slidesToShow: 2,
    //           slidesToScroll: 1,
    //           dots: true,
    //           arrows: false,
    //         }
    //       },
    //       {
    //         breakpoint: 480,
    //         settings: {
    //           slidesToShow: 1,
    //           slidesToScroll: 1,
    //           dots: true,
    //           arrows: false,
    //         }
    //       }
    //     ]
    //   });
    // }
       if ($(".block-solutions").length) {
      $(".block-solutions").slick({
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1025,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              dots: true,
              arrows: false,
            }
          },
          {
            breakpoint: 769,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              dots: true,
              arrows: false,
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true,
              arrows: false,
            }
          }
        ]
      });
    }
    // let context = this;
    // window.addEventListener("unload", function (e) {
    //   if (this.cookie.get('currentUser') != null) {
    //     context.authenticationSevice.logout();
    //   }
    // });
  }
  opentab(routes) {
    this.router.navigate[routes]
  }
  data: any = [{
    "parentName": "Đồng bộ dữ liệu"
  }, {
    "parentName": "AI Viettel Cloud Camera"
  }];
  toggleAccordian(event, index) {
    const element = event.target;
    element.classList.toggle("active");
    if (this.data[index].isActive) {
      this.data[index].isActive = false;
    } else {
      this.data[index].isActive = true;
    }
    const panel = element.nextElementSibling;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
  }
}
