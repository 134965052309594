import { CameraModel } from "./../../_models/camera.model";
import { BunchModel } from "./../../_models/bunch.model";
import { PackageModel, BuyModel } from "./../../_models/package.model";
import { FormValidateService } from "./../../_services/form-validate.service";
import { PackageService } from "./../../_services/package.service";
import { AuthenticationService } from "./../../_services/authentication.service";
import { Component, OnInit, Input } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { AuthHepler } from "../../_helpers/auth.helper";
import { CookieService } from "ngx-cookie-service";
import * as _ from "lodash";
declare var $: any;
import Swal from "sweetalert2";
import { ObjectNodeBunch } from "../../_models/object_tree_bunch";
import { TreeviewService } from "../../_services/treeview.service";
import { LanguageModel } from "src/app/_models/language.model";
import { AppComponent } from "src/app/app.component";

@Component({
  selector: "app-goicuoc",
  templateUrl: "./goicuoc.component.html",
  styleUrls: ["./goicuoc.component.css"],
})
export class GoicuocComponent extends AuthHepler implements OnInit {
  listPackage: PackageModel[];
  bunchListNoBuy: BunchModel[];
  bunchListBought: BunchModel[];
  currCamera: CameraModel = new CameraModel();
  currBunch: BunchModel = new BunchModel();
  packageSelected: PackageModel = new PackageModel();
  listBuy: BuyModel[] = [] as BuyModel[];
  totalMonney = 0;
  totalSum = 0;
  totalVAT = 0;
  packageBalance = 0;
  listData: any[] = [];
  listDataBought: any[] = [];
  parentCollapse: Boolean = false;
  subCollapse: Boolean = false;
  changeIconStatus: any;
  iconStatus: String = "plus";
  beforeId: any = null;
  idSubItem: any = null;
  levelDisplay: any;
  langModel = new LanguageModel();

  constructor(
    private _treeService: TreeviewService,
    public authenticationSevice: AuthenticationService,
    private _fb: FormBuilder,
    public _packageService: PackageService,
    public _packageValidateService: FormValidateService,
    private _appComponent: AppComponent,
    public cookie: CookieService
  ) {
    super(authenticationSevice, authenticationSevice, cookie);
  }
  ngOnInit(): void {
    this.findAll();
    this.getTreeViewNoBuy();
    this.getTreeViewBought();

    //chuyển ngữ
    this._appComponent.translate.get("li_warn").subscribe((res: string) => {
      this.langModel.title = res;
    });
    this._appComponent.translate
      .get("li_no_payment")
      .subscribe((res: string) => {
        this.langModel.content = res;
      });
    this._appComponent.translate
      .get("li_not_bunch")
      .subscribe((res: string) => {
        this.langModel.title1 = res;
      });
    this._appComponent.translate
      .get("ad_cam_had_cart")
      .subscribe((res: string) => {
        this.langModel.content1 = res;
      });
    this._appComponent.translate.get("li_notiffi").subscribe((res: string) => {
      this.langModel.title2 = res;
    });
    this._appComponent.translate.get("ad_pay_succ").subscribe((res: string) => {
      this.langModel.content2 = res;
    });
    this._appComponent.translate
      .get("ad_empty_cart")
      .subscribe((res: string) => {
        this.langModel.title3 = res;
      });
    this._appComponent.translate
      .get("ad_want_Cancel_pack")
      .subscribe((res: string) => {
        this.langModel.content3 = res;
      });
    this._appComponent.translate.get("li_del").subscribe((res: string) => {
      this.langModel.title4 = res;
    });
    this._appComponent.translate.get("li_cancel").subscribe((res: string) => {
      this.langModel.content4 = res;
    });

    this._appComponent.translate
      .get("ad_cancel_succ")
      .subscribe((res: string) => {
        this.langModel.title5 = res;
      });
    this._appComponent.translate.get("").subscribe((res: string) => {
      this.langModel.content5 = res;
    });
  }
  h264Items = [
    { name: this._appComponent.translateText("pack_live"), price: "20.000", save: this._appComponent.translateText("pack_save0day"), resolution: "SD480" },
    { name: this._appComponent.translateText("pack_3day"), price: "35.000", save: this._appComponent.translateText("pack_save3day"), resolution: "SD480" },
    { name:  this._appComponent.translateText("pack_7day"), price: "45.000", save: this._appComponent.translateText("pack_save7day"), resolution: "SD480" },
    { name:  this._appComponent.translateText("pack_14day"), price: "65.000", save: this._appComponent.translateText("pack_save14day"), resolution: "SD480" },
    { name: this._appComponent.translateText("pack_15day"), price: "70.000", save: this._appComponent.translateText("pack_save15day"), resolution: "SD480" },
    { name: this._appComponent.translateText("pack_30day"), price: "110.000", save: this._appComponent.translateText("pack_save30day"), resolution: "SD480" },
    { name: this._appComponent.translateText("pack_60day"), price: "205.000", save: this._appComponent.translateText("pack_save60day"), resolution: "SD480" },
    { name: this._appComponent.translateText("pack_90day"), price: "300.000", save: this._appComponent.translateText("pack_save90day"), resolution: "SD480" },

    { name: this._appComponent.translateText("pack_live"), price: "25.000", save: this._appComponent.translateText("pack_save0day"), resolution: "HD720" },
    { name:  this._appComponent.translateText("pack_3day"), price: "54.000", save: this._appComponent.translateText("pack_save3day"), resolution: "HD720" },
    { name: this._appComponent.translateText("pack_7day"), price: "85.000", save: this._appComponent.translateText("pack_save7day"), resolution: "HD720" },
    { name: this._appComponent.translateText("pack_14day"), price: "120.000", save: this._appComponent.translateText("pack_save14day"), resolution: "HD720" },
    { name: this._appComponent.translateText("pack_15day"), price: "126.000", save: this._appComponent.translateText("pack_save15day"), resolution: "HD720" },
    { name: this._appComponent.translateText("pack_30day"), price: "210.000", save: this._appComponent.translateText("pack_save30day"), resolution: "HD720" },
    { name: this._appComponent.translateText("pack_60day"), price: "400.000", save: this._appComponent.translateText("pack_save60day"), resolution: "HD720" },
    { name: this._appComponent.translateText("pack_90day"), price: "580.000", save: this._appComponent.translateText("pack_save90day"), resolution: "HD720" },
    {
      name: this._appComponent.translateText("pack_live"),
      price: "30.000",
      save: this._appComponent.translateText("pack_save0day"),
      resolution: "FHD1080",
    },
    { name:  this._appComponent.translateText("pack_3day"), price: "70.000", save: this._appComponent.translateText("pack_save3day"), resolution: "FHD1080" },
    { name:  this._appComponent.translateText("pack_7day"), price: "140.000", save: this._appComponent.translateText("pack_save7day"), resolution: "FHD1080" },
    {
      name: this._appComponent.translateText("pack_14day"),
      price: "175.000",
      save: this._appComponent.translateText("pack_save14day"),
      resolution: "FHD1080",
    },
    {
      name: this._appComponent.translateText("pack_15day"),
      price: "200.000",
      save: this._appComponent.translateText("pack_save15day"),
      resolution: "FHD1080",
    },
    {
      name: this._appComponent.translateText("pack_30day"),
      price: "400.000",
      save: this._appComponent.translateText("pack_save30day"),
      resolution: "FHD1080",
    },
    {
      name: this._appComponent.translateText("pack_60day"),
      price: "770.000",
      save: this._appComponent.translateText("pack_save60day"),
      resolution: "FHD1080",
    },
    {
      name: this._appComponent.translateText("pack_90day"),
      price: "1200.000",
      save: this._appComponent.translateText("pack_save90day"),
      resolution: "FHD1080",
    },
  ];

  h265Items = [
    { name: this._appComponent.translateText("pack_live"), price: "20.000", save: this._appComponent.translateText("pack_save0day"), resolution: "SD480" },
    { name: this._appComponent.translateText("pack_3day"), price: "33.000", save: this._appComponent.translateText("pack_save3day"), resolution: "SD480" },
    { name: this._appComponent.translateText("pack_7day"), price: "40.000", save: this._appComponent.translateText("pack_save7day"), resolution: "SD480" },
    { name: this._appComponent.translateText("pack_14day"), price: "45.000", save: this._appComponent.translateText("pack_save14day"), resolution: "SD480" },
    { name: this._appComponent.translateText("pack_15day"), price: "55.000", save: this._appComponent.translateText("pack_save15day"), resolution: "SD480" },
    { name: this._appComponent.translateText("pack_30day"), price: "70.000", save: this._appComponent.translateText("pack_save30day"), resolution: "SD480" },
    { name: this._appComponent.translateText("pack_60day"), price: "110.000", save: this._appComponent.translateText("pack_save60day"), resolution: "SD480" },
    { name: this._appComponent.translateText("pack_90day"), price: "150.000", save: this._appComponent.translateText("pack_save90day"), resolution: "SD480" },

    { name: this._appComponent.translateText("pack_live"), price: "25.000", save: this._appComponent.translateText("pack_save0day"), resolution: "HD720" },
    { name:  this._appComponent.translateText("pack_3day"), price: "43.000", save: this._appComponent.translateText("pack_save3day"), resolution: "HD720" },
    { name: this._appComponent.translateText("pack_7day"), price: "50.000", save: this._appComponent.translateText("pack_save7day"), resolution: "HD720" },
    { name: this._appComponent.translateText("pack_14day"), price: "60.000", save: this._appComponent.translateText("pack_save14day"), resolution: "HD720" },
    { name: this._appComponent.translateText("pack_15day"), price: "65.000", save:  this._appComponent.translateText("pack_save15day"), resolution: "HD720" },
    { name: this._appComponent.translateText("pack_30day"), price: "110.000", save: this._appComponent.translateText("pack_save30day"), resolution: "HD720" },
    { name: this._appComponent.translateText("pack_60day"), price: "210.000", save: this._appComponent.translateText("pack_save60day"), resolution: "HD720" },
    { name: this._appComponent.translateText("pack_90day"), price: "350.000", save: this._appComponent.translateText("pack_save90day"), resolution: "HD720" },
    {
      name: this._appComponent.translateText("pack_live"),
      price: "30.000",
      save: this._appComponent.translateText("pack_save0day"),
      resolution: "FHD1080",
    },
    { name: this._appComponent.translateText("pack_3day"), price: "50.000", save: this._appComponent.translateText("pack_save3day"), resolution: "FHD1080" },
    { name: this._appComponent.translateText("pack_7day"), price: "65.000", save: this._appComponent.translateText("pack_save7day"), resolution: "FHD1080" },
    {
      name: this._appComponent.translateText("pack_14day"),
      price: "100.000",
      save: this._appComponent.translateText("pack_save14day"),
      resolution: "FHD1080",
    },
    {
      name:  this._appComponent.translateText("pack_15day"),
      price: "110.000",
      save:  this._appComponent.translateText("pack_save15day"),
      resolution: "FHD1080",
    },
    {
      name: this._appComponent.translateText("pack_30day"),
      price: "200.000",
      save: this._appComponent.translateText("pack_save30day"),
      resolution: "FHD1080",
    },
    {
      name: this._appComponent.translateText("pack_60day"),
      price: "350.000",
      save: this._appComponent.translateText("pack_save60day"),
      resolution: "FHD1080",
    },
    {
      name: this._appComponent.translateText("pack_90day"),
      price: "700.000",
      save: this._appComponent.translateText("pack_save90day"),
      resolution: "FHD1080",
    },
  ];
  // Biến để giữ danh sách items của tab hiện tại
  selectedItems = this.h264Items;
  ngAfterViewInit(): void {
    this.initializeSlider();
  }
  ngOnDestroy(): void {
    this.destroySlider();
  }
  // Hàm khởi tạo slider với Slick
  initializeSlider(): void {
    $(".package-list").slick({
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      dots: false,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
  }
  // Hàm hủy slider để tránh lỗi khi làm mới
  destroySlider(): void {
    if ($(".package-list").hasClass("slick-initialized")) {
      $(".package-list").slick("unslick");
    }
  }
  // Hàm chọn tab và cập nhật dữ liệu cho slider
  selectTab(tabNumber: number): void {
    this.selectedItems = tabNumber === 1 ? this.h264Items : this.h265Items;
    this.destroySlider();
    setTimeout(() => this.initializeSlider(), 0);
  }
  findAll() {
    this._packageService.findAll().subscribe(
      (res) => {
        this.listPackage = res.listPackage;
        if (this.listPackage?.length > 0) {
          this.packageSelected = this.listPackage[0];
        }
      },
      (error) => {
        if (error === "Unauthorized") {
          this.unAuthHandle(() => this.findAll());
        } else {
          console.log("Lỗi lấy danh sách gói cước " + error);
        }
      },
      () => {}
    );
  }
  // List function tab chưa mua
  getTreeViewNoBuy() {
    this._packageService.getTreeViewNotBuy().subscribe(
      (res) => {
        this.bunchListNoBuy = res.listBunch;
        this.loopMultipleNotBuy(this.bunchListNoBuy);
        if (res.listCamera.length > 0) {
          const bunch = new ObjectNodeBunch();
          const bunchVariable = new BunchModel();
          bunchVariable.name = this.langModel.title1;
          bunchVariable.id = -1;
          bunch.bunch = bunchVariable;
          bunch.listChild = [];
          bunch.isActive = true;
          bunch.level = 0;
          bunch.isClick = false;
          bunch.listCamera = res.listCamera.map((element) => {
            element.display = true;
            return element;
          });

          this.listData.push(bunch);
        }
      },
      (err) => {
        if (err === "Unauthorized") {
          this.unAuthHandle(() => this.getTreeViewNoBuy());
        } else if (err === "Payment Required") {
          Swal.fire(this.langModel.title, this.langModel.content, "warning");
        } else {
          console.log(err);
        }
      }
    );
  }
  getCameraInBunchNoBuy(_bunch: BunchModel) {
    this._packageService.getCameraInBunchNoBuy(_bunch.bunch.id).subscribe(
      (res) => {
        _bunch.listCamera = res.listCamera;
      },
      (err) => {
        if (err === "Unauthorized") {
          this.unAuthHandle(() => this.getCameraInBunchNoBuy(_bunch));
        } else {
          // console.log('getCameraInBunchNoBuy ' + err);
        }
      }
    );
  }
  showBuyBunch($event: any) {
    let haveResolution = true;
    if ($event.listCamera.length > 0) {
      this.currBunch.resolution = $event.listCamera[0].resolution;
      $event.listCamera.forEach((element) => {
        if (element.resolution != this.currBunch.resolution) {
          haveResolution = false;
        }
      });
      if (!haveResolution) {
        this.currBunch.resolution = "";
      }
      if (!haveResolution) {
        Swal.fire(
          this._appComponent.translateText("li_warn"),
          this._appComponent.translateText("pack.warn_content1"),
          "warning"
        );
      } else {
        this.currBunch.name = $event.bunch.name;
        this.currBunch.listCamera = $event.listCamera;
        this.currBunch.id = $event.bunch.id;
        $("#buyDataPackage").modal("show");
      }
    } else {
      Swal.fire(
        this._appComponent.translateText("li_warn"),
        this._appComponent.translateText("pack.warn_content2"),
        "warning"
      );
    }
  }
  showBuyCam($event: any, _idModal: string) {
    const _cam = new CameraModel();
    _cam.id = $event.id ? $event.id : null;
    _cam.name = $event.name ? $event.name : null;
    _cam.package = $event.package ? $event.package : null;
    _cam.dateExpiration = $event.dateExpiration ? $event.dateExpiration : null;
    _cam.resolution = $event.resolution ? $event.resolution : null;
    const listCamId = this.listBuy.map((x) => x.cameraId);
    const isExist = _.includes(listCamId, _cam.id);
    if (isExist) {
      Swal.fire(this.langModel.title, this.langModel.content1, "warning");
    } else {
      this.currCamera = _cam;
      if (_cam.package != null) {
        this.packageSelected = _.find(this.listPackage, {
          id: this.currCamera.package.id,
        });
      }
      $("#" + _idModal).modal("show");
    }
  }
  AddBunchToCart() {
    // this.getCameraInBunchNoBuy(this.currBunch);
    this.currBunch.listCamera.forEach((cam) => {
      const listCamId = this.listBuy.map((x) => x.cameraId);
      const isExist = _.includes(listCamId, cam.id);
      // Bỏ cam trong nhóm đã được thêm vào giỏ hàng
      if (isExist) {
        this.listBuy.forEach((item, index) => {
          if (item.cameraId === cam.id) {
            this.listBuy.splice(index, 1);
          }
        });
      }
      const buyCam = new BuyModel();
      buyCam.cameraId = cam.id;
      buyCam.cameraName = cam.name;
      buyCam.packageId = this.packageSelected.id;
      buyCam.packageName = this.packageSelected.name;
      buyCam.price = this.packageSelected.price;
      buyCam.resolution = cam.resolution;
      this.listBuy.push(buyCam);
    });
    this.RecountMoney();
    $("#buyDataPackage").modal("hide");
  }
  AddToCart(_idModal: string) {
    const buyCam = new BuyModel();
    buyCam.cameraId = this.currCamera.id;
    buyCam.cameraName = this.currCamera.name;
    buyCam.resolution = this.currCamera.resolution;
    buyCam.packageId = this.packageSelected.id;
    buyCam.packageName = this.packageSelected.name;
    buyCam.price = this.packageSelected.price;
    this.listBuy.push(buyCam);
    this.RecountMoney();
    $("#" + _idModal).modal("hide");
  }
  RemoveFromCart(_buy: BuyModel) {
    this.listBuy.forEach((item, index) => {
      if (item === _buy) {
        this.listBuy.splice(index, 1);
      }
    });
    this.RecountMoney();
  }
  RecountMoney() {
    if (this.listBuy?.length > 0) {
      this.totalMonney = this.listBuy
        .map((a) => a.price)
        .reduce(function (a, b) {
          return a + b;
        });
      this.totalVAT = this.totalMonney / 10;
      this.totalSum = this.totalMonney + this.totalVAT;
    } else {
      this.totalMonney = 0;
      this.totalVAT = 0;
      this.totalSum = this.totalMonney + this.totalVAT;
    }
  }
  ResetTemp() {
    this.listBuy = [] as BuyModel[];
    this.totalMonney = 0;
    this.totalVAT = 0;
    this.totalSum = this.totalMonney + this.totalVAT;
    this.getTreeViewNoBuy();
    this.getTreeViewBought();
  }
  BuyPackage() {
    if (this.listBuy?.length > 0) {
      Swal.fire({
        title: this._appComponent.translateText("pack.title_fee"),
        text:  this._appComponent.translateText("pack.ques_fee"),
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#E03",
        confirmButtonText: this._appComponent.translateText("pack.ok"),
        cancelButtonText:  this._appComponent.translateText("pack.cancel"),
      }).then((result) => {
        if (result.value) {
          this._packageService.buyPackage(this.listBuy).subscribe(
            (res) => {
              this.ResetTemp();
              Swal.fire(
                this.langModel.title2,
                this.langModel.content2,
                "success"
              ).then(() => this.getTreeViewNoBuy());
              this.getBalance();
            },
            (err) => {
              if (err === "Payment Required") {
                Swal.fire(
                  this.langModel.title,
                  this.langModel.content,
                  "warning"
                );
              } else {
                console.log("Lỗi thanh toán " + err);
              }
            }
          );
        } else if (result.dismiss) {
          this.getTreeViewNoBuy();
          this.getBalance();
        }
      });
    } else {
      Swal.fire(this.langModel.title2, this.langModel.title3, "warning");
    }
  }
  // List function tab đã mua
  getTreeViewBought() {
    this._packageService.getTreeViewBought().subscribe(
      (res) => {
        this.bunchListBought = res.listBunch;
        console.log(res);
        this.loopMultipleBought(this.bunchListBought);
        if (res.listCamera.length > 0) {
          const bunch = new ObjectNodeBunch();
          const bunchVariable = new BunchModel();
          bunchVariable.name = this._appComponent.translateText("li_not_bunch");
          bunchVariable.id = -1;
          bunch.bunch = bunchVariable;
          bunch.listChild = [];
          bunch.isActive = true;
          bunch.level = 0;
          bunch.isClick = false;
          bunch.listCamera = res.listCamera.map((element) => {
            element.display = true;
            return element;
          });

          this.listDataBought.push(bunch);
        }
      },
      (err) => {
        if (err === "Unauthorized") {
          this.authenticationSevice.logout();
        } else if (err === "Payment Required") {
          Swal.fire(this.langModel.title, this.langModel.content, "warning");
        } else {
          // console.log(err);
        }
      }
    );
  }
  getCameraInBunchBought(_bunch: BunchModel) {
    this._packageService.getCameraInBunchBought(_bunch.bunch.id).subscribe(
      (res) => {
        _bunch.listCamera = res.listCamera;
      },
      (err) => {
        if (err === "Unauthorized") {
        }
      }
    );
  }
  cancelPackage($event) {
    var _cam = new CameraModel();
    _cam = $event;
    Swal.fire({
      title: this.langModel.title2,
      text: this.langModel.content3,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: this.langModel.title4,
      cancelButtonText: this.langModel.content4,
    }).then((result) => {
      if (result.value) {
        this._packageService.cancelPackage(_cam).subscribe(
          (res) => {
            Swal.fire(
              this.langModel.title2,
              this.langModel.title5,
              "success"
            ).then(() => this.getTreeViewBought());
          },
          (err) => {
            if (err === "Unauthorized") {
              this.unAuthHandle(() => this.cancelPackage(_cam));
            } else {
              console.log("Lỗi hủy gói cước " + err);
            }
          }
        );
      } else if (result.dismiss) {
      }
    });
  }
  getBalance() {
    this.authenticationSevice.getbalance().subscribe(
      (res) => {
        this.packageBalance = res.amount.toLocaleString();
      },
      (err) => {
        if (err === "Unauthorized") {
          this.unAuthHandle(() => this.getBalance());
        } else if (err === "Payment Required") {
          Swal.fire(this.langModel.title, this.langModel.content, "warning");
        } else {
          console.log("Lỗi lấy số dư tài khoản " + err);
        }
      }
    );
  }
  // Loop through NotBuy bunches
  loopMultipleNotBuy(listParent: any[]) {
    let i = 0;
    const listLength = listParent.length;
    const parent = [];
    while (i < listLength) {
      if (listParent[i].parentId == null) {
        const obj = new ObjectNodeBunch();
        obj.level = 0;
        obj.bunch = listParent[i];
        obj.isActive = true;
        this.getCameraInBunchNoBuyMaptoObjectModel(obj);
        parent.push(obj);
      }
      i++;
    }
    parent.forEach((element) => {
      listParent.splice(listParent.indexOf(element.bunch), 1);
    });
    this.nodeStructerNotBuy(parent);
  }
  nodeStructerNotBuy(list: any[]) {
    let parent = [];
    let data = [];
    list.forEach((element) => {
      parent.push(element);
      data = this.sortingChildrenNotBuy(element);
      parent = parent.concat(data);
    });
    this.listData = parent;
  }
  sortingChildrenNotBuy(item) {
    let result = [];
    let data = [];
    this.bunchListNoBuy.forEach((element) => {
      if (element.parentId == item.bunch.id) {
        const bunch = new ObjectNodeBunch();
        bunch.level = item.level + 1;
        bunch.bunch = element;
        item.listChild.push(element.id);
        this.getCameraInBunchNoBuyMaptoObjectModel(bunch);
        result.push(bunch);
        // this.bunchList.splice(this.bunchList.indexOf(element),1);
        data = this.sortingChildrenNotBuy(bunch);
        result = result.concat(data);
      }
    });
    return result;
  }
  getCameraInBunchNoBuyMaptoObjectModel(_bunch: any) {
    this._packageService.getCameraInBunchNoBuy(_bunch.bunch.id).subscribe(
      (res) => {
        _bunch.listCamera = res.listCamera.map((element) => {
          element.display = true;
          return element;
        });
      },
      (err) => {
        if (err === "Unauthorized") {
          this.unAuthHandle(() =>
            this.getCameraInBunchNoBuyMaptoObjectModel(_bunch.bunch.id)
          );
        } else {
          // console.log("getCameraInBunch " + err);
        }
      }
    );
  }
  // Loop through bought bunches
  getCameraInBunchBoughtMaptoObjectModel(_bunch: any) {
    this._packageService.getCameraInBunchBought(_bunch.bunch.id).subscribe(
      (res) => {
        _bunch.listCamera = res.listCamera.map((element) => {
          element.display = true;
          return element;
        });
      },
      (err) => {
        if (err === "Unauthorized") {
          this.unAuthHandle(() =>
            this.getCameraInBunchBoughtMaptoObjectModel(_bunch.bunch.id)
          );
        } else {
          // console.log("getCameraInBunch " + err);
        }
      }
    );
  }
  loopMultipleBought(listParent: any[]) {
    let i = 0;
    const listLength = listParent.length;
    const parent = [];
    while (i < listLength) {
      if (listParent[i].parentId == null) {
        const obj = new ObjectNodeBunch();
        obj.level = 0;
        obj.bunch = listParent[i];
        obj.isActive = true;
        this.getCameraInBunchBoughtMaptoObjectModel(obj);
        parent.push(obj);
      }
      i++;
    }
    parent.forEach((element) => {
      listParent.splice(listParent.indexOf(element.bunch), 1);
    });
    this.nodeStructerBought(parent);
  }
  nodeStructerBought(list: any[]) {
    let parent = [];
    let data = [];
    list.forEach((element) => {
      parent.push(element);
      data = this.sortingChildrenBought(element);
      parent = parent.concat(data);
    });
    this.listDataBought = parent;
    console.log("data bought");
    console.log(this.listDataBought);
  }
  sortingChildrenBought(item) {
    let result = [];
    let data = [];
    this.bunchListBought.forEach((element) => {
      if (element.parentId == item.bunch.id) {
        const bunch = new ObjectNodeBunch();
        bunch.level = item.level + 1;
        bunch.bunch = element;
        item.listChild.push(element.id);
        this.getCameraInBunchBoughtMaptoObjectModel(bunch);
        result.push(bunch);
        // this.bunchList.splice(this.bunchList.indexOf(element),1);
        data = this.sortingChildrenBought(bunch);
        result = result.concat(data);
      }
    });
    return result;
  }
  changeCollape() {
    if (this.iconStatus === "plus") {
      this.iconStatus = "minus";
    } else {
      this.iconStatus = "plus";
    }
  }
  // Get data from child GoiCuocBought component to enable/disable sub-children display
  getSubIdparent($event) {
    this.beforeId = this.idSubItem;
    this.idSubItem = $event.bunch.id;
    this.levelDisplay = $event.level;
  }
  getIsClick($event) {
    if ($event === true) {
      this.disableLevelBought();
    }
    this.disableChildrenBought($event, this.idSubItem);
  }
  disableLevelBought() {
    this.listDataBought.forEach((element) => {
      if (element.level > this.levelDisplay) {
        element.isActive = false;
        element.isClick = false;
      } else {
        if (
          element.level == this.levelDisplay &&
          element.bunch.id != this.idSubItem
        ) {
          element.isClick = false;
        }
      }
    });
  }
  disableChildrenBought($event: any, id: any) {
    this.listDataBought.forEach((element) => {
      if (element.bunch.parentId == id) {
        element.isActive = $event;
        if ($event === false) {
          element.isClick = $event;
          this.disableChildrenBought($event, element.bunch.id);
        }
      }
    });
  }
  // Get data from child GoiCuocNotBuy component to enable/disable sub-children display
  getIsClickNotBuy($event) {
    if ($event === true) {
      this.disableLevelNotBuy();
    }
    this.disableChildrenNotBuy($event, this.idSubItem);
  }
  disableLevelNotBuy() {
    this.listData.forEach((element) => {
      if (element.level > this.levelDisplay) {
        element.isActive = false;
        element.isClick = false;
      } else {
        if (
          element.level == this.levelDisplay &&
          element.bunch.id != this.idSubItem
        ) {
          element.isClick = false;
        }
      }
    });
  }
  disableChildrenNotBuy($event: any, id: any) {
    this.listData.forEach((element) => {
      if (element.bunch.parentId == id) {
        element.isActive = $event;
        if ($event === false) {
          element.isClick = $event;
          this.disableChildrenNotBuy($event, element.bunch.id);
        }
      }
    });
  }
  getItemStyle(item) {
    var style = "";
    if (item.bunch.parentId > 0) {
      style += " background-color: #f1f1f5; ";
    }
    return style;
  }
}
