<div class="landing position-relative">
  <header class="landing-header">
    <nav class="navbar navbar-expand-xl navbar-dark p-0">
      <div class="container-fluid">
        <div class="row w-100">
          <div class="col-12 col-xl-2">
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#idcMenu"
              aria-controls="idcMenu" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="brand-logo">
              <a href="/home">
                <img class="logo" src="assets/logo camera.svg" alt="Cloud Camera" />
              </a>
            </div>
          </div>
          <div class="col-12 col-xl-10 clearfix">
            <div class="collapse navbar-collapse" id="idcMenu">
              <ul>
                <li >
                  <a href="#" (click)="goToSection($event, 'sectionBanners')" translate>{{'ldp_homepage'}}
                  </a>
                </li>
                <li>
                  <a  href="#" (click)="goToSection($event, 'sectionFeatures')" translate>{{'ldp_function'}}
                  </a>
                </li>
                <li>
                  <a href="#" (click)="goToSection($event, 'sectionSolutions')" translate>{{'ldp_solution'}}
                  </a>
                </li>
                <li>
                  <a href="#" (click)="goToSection($event, 'sectionDownload')" translate>{{'ldp_download'}}
                  </a>
                </li>
                <li>
                  <a href="#" (click)="goToSection($event, 'sectionManual')" translate>{{'ldp_Document'}}
                  </a>
                </li>
                <li>
                  <a href="#" (click)="goToSection($event, 'sectionContact')" translate>{{'ldp_contact'}}
                  </a>
                </li>
              </ul>
              <div class="user-menu">
                <a class="btn-text" routerLink="/register">
                  <span class="login">{{'ldp_register'|translate}}</span></a>
                <button class="btn btn-primary" routerLink="/login">{{'ldp_login'|translate}}</button>
                  <div class="dropdown dropdown-language">
                    <a class="dropdown-toggle" href="javascript:;" role="button" id="dropdownLanguage"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownLanguage">
                    </div>
                  </div> 
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </header>
  <div class="page-container">
    <section class="section-banner " id="sectionBanner ">
      <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
        <button class="btn-primary btn-consult"  onclick="window.open('https://viettelidc.com.vn/Home/Contact','_blank')" translate>{{'ldp_presale'}}</button>
        <ol class="carousel-indicators">
          <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
        </ol>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img class="d-block w-100" src="assets/landing/banner.png" alt="First slide">
            <div class="container ">
              <div class="carousel-caption ">
                <h3 class="title-banner" translate>{{'ldp_sln_online_optimal'}}</h3>
                <p translate>{{'ldp_sld1'}}</p>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <img class="d-block w-100" src="assets/landing/banner2.png" alt="Second slide">
            <div class="carousel-caption ">
              <h3 class="title-banner" translate>{{'ldp_sln_online_optimal1'}}</h3>
              <p translate>{{'ldp_sld2'}}</p>
            </div>
          </div>
          <div class="carousel-item">
            <img class="d-block w-100" src="assets/landing/banner3.png" alt="Third slide">

            <div class="carousel-caption ">
              <h3 class="title-banner" translate>{{'ldp_sln_online_optimal2'}}</h3>
              <p translate>{{'ldp_sld3'}}</p>
            </div>
          </div>
        </div>
        <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>

      </div>
    </section>



    <section class="landing-news">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="landing-news-wrapper">
              <h5 style="font-weight: bold;
              font-size: 24px;
              line-height: 30px;
              text-align: center;" translate>{{'ldp_app_of_vCC'}}</h5>
              <div class="container p-md-0 ">
                <div class="row no-gutters mb-5 mb-md-0 ">
                  <div class="col-md-6 ">
                    <div class="block-feature">
                      <div class="block-feature__inner ">
                        <ul class="block-feature__list ">
                          
                          <li>
                            <img src="assets/landing/icons/check-cube.svg" alt="" />
                            <span translate>{{'ldp_content_plug_play'}}</span>
                          </li>
                          <li>
                            <img src="assets/landing/icons/check-cube.svg" alt="" />
                            <span translate>{{'ldp_content_store1'}}</span>
                          </li>
                          <li>
                            <img src="assets/landing/icons/check-cube.svg " alt=" " />
                            <span translate>{{'ldp_content_store2'}}</span>
                          </li>
                          <li>
                            <img src="assets/landing/icons/check-cube.svg " alt=" " />
                            <span translate>{{'ldp_content_store3'}}</span>
                          </li>
                          <li>
                            <img src="assets/landing/icons/check-cube.svg " alt=" " />
                            <span translate>{{'ldp_content_store4'}}</span>
                          </li>
                          <li>
                            <img src="assets/landing/icons/check-cube.svg " alt=" " />
                            <span translate>{{'ldp_content_store5'}}</span>
                          </li>
                          <li>
                            <img src="assets/landing/icons/check-cube.svg " alt=" " />
                            <span translate>{{'ldp_content_store6'}}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 ">
                    <div class="block-feature-img ">
                      <img src="assets/images/camera_school.png" alt="Camera trường học" style="width: 92%;"/>
                    </div>
                  </div>
                </div>
              </div>


              <div class="news-detail-body">
                <div class="news-content">
                  <h5 class="news-content-title" translate>{{'ldp_model'}}</h5>
                  <div>
                      <img src="assets/images/mohinhketnoi.jpg">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End News List -->
    <section class="other-news">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <h5 class="other-news-title" translate>{{'ldp_sln_other'}}</h5>
          </div>
        </div>
        <section class="section-solutions py-0" id="sectionSolutions">
          <div class="container">
            <app-slider-solution></app-slider-solution>
          </div>
        </section>
      </div>
    </section>
    <!-- End News List -->
  </div>
  <app-home-footer></app-home-footer>
</div>