<div class="container-fluid p-0">
    <app-header></app-header>
    <div class="page-container page-user">
        <div class="container">
            <div class="page-action">
                <div class="row">
                    <div class="col-12">
                        <div class="row" style="justify-content: flex-start;">
                            <div class="col-12 col-md-4">
                                <div class="search-form user-search">
                                    <label for="search-user" class="sr-only">{{'ad_search'|translate}}</label>
                                    <input type="text" class="form-control border-control" id="search-user"
                                        placeholder="{{'ad_search'|translate}}" [(ngModel)]="term">
                                </div>
                            </div>

                            <div class="filter-dropdown camera-filter" style="margin-left: 10px;">
                                <div class="btn-group dropdown">
                                    <button class="btn dropdown-toggle border-control" type="button"
                                        data-toggle="dropdown" aria-expanded="false">
                                        <span class="title" style="font-size: unset;">{{titleBunch}}</span>
                                    </button>
                                    <ul class="dropdown-menu" role="menu">
                                        <li *ngFor="let bunch of listBunch"><a href="javascript:;"
                                                (click)="getFilterBunch(bunch)">{{bunch.bunchName}}</a></li>
                                    </ul>
                                </div>
                            </div>

                            <div class="filter-dropdown camera-filter" style="margin-left: 10px;">
                                <div class="btn-group dropdown">
                                    <button class="btn dropdown-toggle border-control" type="button"
                                        data-toggle="dropdown" aria-expanded="false">
                                        <span class="title" style="font-size: unset;">{{titleSelect}}</span>
                                    </button>
                                    <ul class="dropdown-menu" role="menu">
                                        <li *ngFor="let status of listStatus"><a href="javascript:;"
                                                (click)="getFilter(status)">{{status.value}}</a></li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col-12 col-sm-6">
                    </div>
                </div>
            </div>
            <div class="page-table">
                <div class="row no-gutters">
                    <div class="col-12 col-sm-6">
                        <div class="camera-result">
                            {{'li_have'|translate}} <span class="count">{{camDisconnects?.length}}</span> camera
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 text-right">

                        <select class="d-inline-block border-control" (change)="pageSize = $event.target.value"
                            style="padding: 0.375rem 1rem;">
                            <option value="5">{{'ad_view'|translate}} 5</option>
                            <option value="10" selected>{{'ad_view'|translate}} 10</option>
                            <option value="20">{{'ad_view'|translate}} 20</option>
                            <option value="50">{{'ad_view'|translate}} 50</option>
                            <option value="100">{{'ad_view'|translate}} 100</option>
                            <option value="1000">{{'ad_view'|translate}} 1000</option>
                        </select>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table table-striped user-table">
                        <thead>
                            <tr class="header-table" style="padding:15px 0px 15px 0px; text-align: center;">
                                <th scope="col">{{'ad_stt'|translate}}</th>
                                <th scope="col">{{'ad_camera_name'|translate}}</th>
                                <th scope="col">{{'ad_connectCC'|translate}}</th>
                                <th scope="col">{{'ad_timedisconnect'|translate}}</th>
                            </tr>
                        </thead>
                <tbody *ngIf="isLoading">
                    <tr>
                      <td colspan="5" style="text-align: center;">
                        <span>Loading...</span>
                      </td>
                    </tr>
                  </tbody>
                        <tbody *ngIf="!isLoading && camDisconnects && camDisconnects.length > 0">
                            <tr style="padding:15px 0px 15px 0px; text-align: center;"
                                *ngFor="let _cam of camDisconnects|search:{label:['cameraName','bunchName'],keyword:term||''}|orderBy : order||'cameraName'| paginate: { itemsPerPage: pageSize || 10, currentPage: currentPage, totalItems: camDisconnects.length }; let i=index;">
                                <td>{{ pageSize*(currentPage-1)+i+1 || i+1 }}</td>
                                <td>{{_cam.cameraName}}</td>
                                <td>
                                    <div *ngIf="_cam.connectionStatus=='CONNECTED'">
                                        <span class="camera-status active"></span> {{'ad_connect'|translate}}
                                    </div>
                                    <div *ngIf="_cam.connectionStatus=='DISCONNECTED'">
                                        <span class="camera-status inactive"></span> {{'ad_no_connect'|translate}}
                                    </div>
                                </td>
                                <td>{{_cam.timeDisconnected | date : "dd/MM/y HH:mm"}}</td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="!isLoading && camDisconnects && camDisconnects.length === 0">
                            <tr>
                                <td colspan="5" style="text-align: center;" translate>{{"no_record"}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="text-right">
                    <nav class="nav-pagination">
                        <pagination-template #p="paginationApi" (pageChange)="currentPage = $event;">
                            <ul *ngIf="pageSize<=p.getTotalItems()" class="pagination">
                                <li class="page-item" [class.disabled]="p.isFirstPage()">
                                    <a *ngIf="!p.isFirstPage()" class="page-link" (click)="p.previous()">
                                        <i class="icon icon-arrow-left"></i></a>
                                </li>

                                <li class="page-item" [class.active]="p.getCurrent()===page.value"
                                    *ngFor="let page of p.pages">
                                    <a class="page-link" (click)="p.setCurrent(page.value)">{{page.label}}</a>
                                </li>

                                <li class="page-item" [class.disabled]="p.isLastPage()">
                                    <a *ngIf="!p.isLastPage()" class="page-link" (click)="p.next()">
                                        <i class="icon icon-arrow-right"></i></a>
                                </li>
                            </ul>
                        </pagination-template>
                    </nav>
                </div>
            </div>


        </div>
    </div>
    <footer class="idc-footer">
        <p class="copyright">{{'ldp_copyright'|translate}}</p>
    </footer>
</div>