import { CameraModel } from './../../_models/camera.model';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { AccountModel } from './../../_models/account.model';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { AccountService } from '../../_services/account.service';
import { AuthenticationService } from '../../_services/authentication.service';

import { AuthHepler } from '../../_helpers/auth.helper';
import { FormValidateService } from '../../_services/form-validate.service';
import { SearchPipe } from '../../_pipes/search.pipe';
import * as _ from 'lodash';
import Swal from 'sweetalert2'
import { RoleModel } from 'src/app/_models/role.model';
import { CookieService } from 'ngx-cookie-service';
import { LanguageModel } from 'src/app/_models/language.model';
import { AppComponent } from 'src/app/app.component';
declare var $: any;

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent extends AuthHepler implements OnInit {
  @Input() account: AccountModel;
  listAccount: AccountModel[];
  loaded: boolean = false;
  term: string = '';
  order: string = 'fullName';
  pageSize: number = 10;
  currentPage: number = 1;
  userForm: FormGroup;
  updateForm: FormGroup;
  password: string;
  listCamAssign: CameraModel[];
  listCamAllowAssign: CameraModel[];
langModel = new LanguageModel();

  constructor(private _accountService: AccountService,
    public authenticationSevice: AuthenticationService,
    private _fb: FormBuilder,
    public _accValidateService: FormValidateService,
    private _appComponent: AppComponent,
    public cookie: CookieService) {
    super(authenticationSevice, authenticationSevice, cookie);
    this.account = new AccountModel();
  }

  ngOnInit(): void {
    this.findAll();
    this.createForm();
    //chuyển ngữ
    this._appComponent.translate.get('ad_add_new_user').subscribe((res: string) => {
      this.langModel.title = res;
    });
    this._appComponent.translate.get('li_add_user_succ').subscribe((res: string) => {
      this.langModel.content = res;
    });
    this._appComponent.translate.get('li_warn').subscribe((res: string) => {
      this.langModel.title1 = res;
    });
    this._appComponent.translate.get('li_no_payment').subscribe((res: string) => {
      this.langModel.content1 = res;
    });
    this._appComponent.translate.get('ad_change_user').subscribe((res: string) => {
      this.langModel.title2 = res;
    });
    this._appComponent.translate.get('li_change_user_succ').subscribe((res: string) => {
      this.langModel.content2 = res;
    });
    this._appComponent.translate.get('li_warn').subscribe((res: string) => {
      this.langModel.title3 = res;
    });
    this._appComponent.translate.get('li_no_payment').subscribe((res: string) => {
      this.langModel.content3 = res;
    });
    this._appComponent.translate.get('li_update_role').subscribe((res: string) => {
      this.langModel.title4 = res;
    });
    this._appComponent.translate.get('li_update_role_succ').subscribe((res: string) => {
      this.langModel.content4 = res;
    });


    
    this._appComponent.translate.get('li_limit_camera').subscribe((res: string) => {
      this.langModel.title8 = res;
    });
    this._appComponent.translate.get('ad_search').subscribe((res: string) => {
      this.langModel.title9 = res;
    });
  }
  createForm() {
    this.userForm = this._fb.group({
      username: ['', this._accValidateService.customerFormValidate.usernameValidate.validator],
      password: ['', this._accValidateService.customerFormValidate.passwordValidate.validator],
      fullName: ['', this._accValidateService.customerFormValidate.fullNameValidate.validator],
      email: ['', this._accValidateService.customerFormValidate.emailValidate.validator],
      phoneNumber: [''],
      personalId: [''],
      address: ['']
    });
    this.updateForm = this._fb.group({
      username: ['', this._accValidateService.customerFormValidate.usernameValidate.validator],
      fullName: ['', this._accValidateService.customerFormValidate.fullNameValidate.validator],
      email: ['', this._accValidateService.customerFormValidate.emailValidate.validator],
      phoneNumber: [''],
      personalId: [''],
      address: [''],
      isActive: [true],
      isView: [true]
    });
  }
  findAll() {
    this.loaded = false;
    this._accountService.findAll()
      .subscribe(
        res => {
          this.listAccount = res.listAccount;
        },
        error => {
          if (error === 'Unauthorized') {
            this.unAuthHandle(() => this.findAll());
          }
          else {
            console.log(error);
          }
        },
        () => { this.loaded = true; });
  }
  CreateUser() {
    this._accountService.createNew(this.account, this.password)
      .subscribe(
        res => {
          $('#addUser').modal('hide');
          Swal.fire(this.langModel.title,this.langModel.content, 'success')
            .then(() => this.findAll());
        },
        err => {
          if (err === 'Unauthorized') {
            this.unAuthHandle(() => this.CreateUser());
          }
          else {
            Swal.fire('Lỗi cập nhật người dùng', 'Tên đăng nhập hoặc email đã tồn tại, vui lòng kiểm tra lại', 'error');
          }
        }
      );
  }
  UpdateUser() {
    if (this.account.isActive == true) {
      this.account.status = 'ACTIVE';
    }
    else {
      this.account.status = 'LOCK';
    }
    this._accountService.update(this.account)
      .subscribe(
        res => {
          $('#updateUser').modal('hide');
          Swal.fire(this.langModel.title2, this.langModel.content2, 'success')
            .then(() => this.findAll());
        },
        err => {
          if (err === 'Unauthorized') {
            this.unAuthHandle(() => this.UpdateUser());
          }
          else {
            Swal.fire('Lỗi cập nhật người dùng', 'Tên đăng nhập hoặc email đã tồn tại, vui lòng kiểm tra lại', 'error');
            console.log('lỗi sửa người dùng ' + err);
          }
        }
      );
  }
  ShowModal() {
    this.account = new AccountModel();
    $('#addUser').modal('show');
  }
  ShowEditAccount(_account: AccountModel) {
    this.account = _account;
    if (this.account.status == 'ACTIVE')
      this.account.isActive = true;
    else this.account.isActive = false;
    $('#updateUser').modal('show');
  }
  ShowUserConfig(_account: AccountModel) {
    this.account = _account;
    this._accountService.listCameraAllowAsign()
      .subscribe(result => {
        this.listCamAllowAssign = result.listCamera;
        this._accountService.listCameraAssign(_account.username).subscribe(
          res => {
            this.listCamAssign = [] as CameraModel[];
            res.listCamera.forEach(element => {
              //Set thuộc tính cam được chọn cột bên phải
              let cam = new CameraModel();
              cam.selected = true;
              cam.id = element.id;
              cam.name = element.name;
              this.listCamAssign.push(cam);
              this.listCamAllowAssign = this.listCamAllowAssign.filter(function (item) {
                return item.id !== element.id;
              })
            });
            let listTotal = this.listCamAllowAssign.concat(this.listCamAssign);
            //Ghi lại ds cam đã đc gán
            localStorage.setItem('camSelected', JSON.stringify(this.listCamAssign));
            $("#select-camera-group1").empty();
            if ($("#select-camera-group1").length) {
              let settings = {
                dataArray: listTotal,
                itemName: "name",
                valueName: "id",
                searchPlaceholderText: this.langModel.title9,
                tabNameText: "",
                rightTabNameText: "",
                callable: function (items) {
                  localStorage.setItem('camSelected', JSON.stringify(items));
                }
              };
              $("#select-camera-group1").transfer(settings);
            }
            if (this.account.role != null && this.account.role?.id == 3)
              this.account.isView = false;
            else this.account.isView = true;
          },
          err => {
            console.log(err);
          }
        );
      },
        err => {
          console.log(err);
        });
    $('#configUser').modal('show');
  }
  SaveUserConFig() {
    let idRole = "2";
    if (this.account.isView == true)
      idRole = "2";
    else idRole = "3";
    let selectedCamIds = [];
    let list = JSON.parse(localStorage.getItem('camSelected'));
    if (list != '') {
      list.forEach(item => {
        selectedCamIds.push(item.id);
      });
    }
    this._accountService.UpdateConfig(this.account.username, idRole, selectedCamIds)
      .subscribe(
        res => {
          $('#configUser').modal('hide');
          Swal.fire(this.langModel.title4, this.langModel.content4, 'success')
            .then(() => this.findAll());
        },
        err => {
          if (err.status == 402) {
            let error = err.json();
            Swal.fire(this.langModel.title8, `${error.message}`, 'error')
          }
          else if (err.status === 401) {
            this.unAuthHandle(() => this.SaveUserConFig());
          }
          else {
            console.log('Lỗi cập nhật quyền ' + err);
          }
        });
  }
}
