import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterSearch'
})

export class FilterSearchPipe implements PipeTransform {
    transform(items: any[], config: { label: string[], keyword: string }): any[] {
      this.reset(items); // Gọi hàm reset trước khi transform
        if (Array.isArray(items)) {
          if (config && config.keyword) {
            items.forEach(item => {
              if(item.isView == true)
              item.isView = this.check(item, config.label, config.keyword); // Gán giá trị view
            });
            return items.filter(item => item.isView); // Chỉ trả về các mục có view = true
          } else {
            // Trường hợp không có từ khóa: đặt view = true cho tất cả và trả lại danh sách
            this.reset(items);
            // items.forEach(item => {
            //   item.isView = true;
            // });
            return items;
          }
        } else {
          return [];
        }
      }
      
      check(item: any, props: string[], keyword: string): boolean {
        for (let i = 0; i < props.length; i++) {
          if (item[props[i]] && `${item[props[i]]}`.toLowerCase().indexOf(keyword.toLowerCase()) !== -1) { 
            return true; 
          }
        }
        return false;
      }
      reset(items: any[]): void {
        if (Array.isArray(items)) {
          items.forEach(item => {
            item.isView = item.originView; // Đặt lại giá trị mặc định
          });
        }
      }
}