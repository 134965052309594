import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-solution-store',
  templateUrl: './solution-store.component.html',
  styleUrls: ['./solution-store.component.css']
})
export class SolutionStoreComponent implements OnInit {
  constructor(private router: Router) { }
  goToSection(event: Event, fragment: string) {
    event.preventDefault();
    this.router.navigate(['/'], { fragment: fragment });
  }
  ngOnInit(): void {
    $('.news-slide').slick({
      dots: true,
      infinite: true,
      arrows: true,
      autoplay: true,
      initialSlide: 1,
      autoplaySpeed: 5000,
      prevArrow: "<button type='button' class='slick-prev pull-left'><i class='icon icon-arrow_prev'></i></button>",
      nextArrow: "<button type='button' class='slick-next pull-right'><i class='icon icon-arrow_next'></i></button>",
      slidesToShow: 1,
      slidesToScroll: 1,
    });

  if ($("#backToTop").length){
    $(window).scroll((function () {
      if ($(this).scrollTop() >= 50) {        // If page is scrolled more than 50px
        $('#backToTop').fadeIn(200);    // Fade in the arrow
      } else {
        $('#backToTop').fadeOut(200);   // Else fade out the arrow
      }
    }));
    $("#backToTop").click((function (e) {
      e.preventDefault();
      $("html, body").animate({scrollTop: 0}, 1000);
    }));
  }
  }

}
