import { Component, OnInit } from "@angular/core";
import { forEach } from "lodash";
import { CookieService } from "ngx-cookie-service";
import { BunchModel, CameraModel, CameraOffModel } from "src/app/_models";
import { LanguageModel } from "src/app/_models/language.model";
import { CameraService, ExportService } from "src/app/_services";
import { BunchService } from "src/app/_services/bunch.service";
import { AppComponent } from "src/app/app.component";
import Swal from "sweetalert2";
declare var $: any;
declare var moment: any;

@Component({
  selector: "app-camera-off",
  templateUrl: "./camera-off.component.html",
  styleUrls: ["./camera-off.component.css"],
})
export class CameraOffComponent implements OnInit {
  listBunch: BunchModel[];
  timeSearch: string = "";
  term: string = "";
  order: string = "fullName";
  pageSize: number = 10;
  currentPage: number = 1;
  isLoading = true; // Mặc định là đang tải
  titleBunch: string = "Chọn nhóm camera";
  currBunch: BunchModel = new BunchModel();
  lstCameraOff: CameraOffModel[] = [];
  langModel = new LanguageModel();
  hours = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  minutes = [
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
    "32",
    "33",
    "34",
    "35",
    "36",
    "37",
    "38",
    "39",
    "40",
    "41",
    "42",
    "43",
    "44",
    "45",
    "46",
    "47",
    "48",
    "49",
    "50",
    "51",
    "52",
    "53",
    "54",
    "55",
    "56",
    "57",
    "58",
    "59",
  ];
  constructor(
    private _cameraService: CameraService,
    private _appComponent: AppComponent,
    public cookie: CookieService,
    private _exportService: ExportService,
    private _bunchService: BunchService
  ) {}

  ngOnInit(): void {
    $("#iCamera").addClass("active");
    var tzoffset = new Date().getTimezoneOffset() * 60000;
    this.timeSearch = new Date(Date.now() - tzoffset)
      .toISOString()
      .slice(0, -1)
      .substring(0, 16);
    this.loadListBunch();
    //chuyển ngữ
    this._appComponent.translate
      .get("cam_err_time")
      .subscribe((res: string) => {
        this.langModel.title = res;
      });
    this._appComponent.translate
      .get("cam_cont_time")
      .subscribe((res: string) => {
        this.langModel.content = res;
      });
    this._appComponent.translate
      .get("li_not_bunch")
      .subscribe((res: string) => {
        this.langModel.title1 = res;
      });
    this._appComponent.translate.get("ad_activiti").subscribe((res: string) => {
      this.langModel.content1 = res;
    });
    this._appComponent.translate
      .get("ad_not_activiti")
      .subscribe((res: string) => {
        this.langModel.title2 = res;
      });
      this.searchCamWithTime();
  }
  searchCamWithTime() {
    let numTimeSerach = new Date(this.timeSearch).getTime();
    let last30day = new Date().getTime() - 30 * 24 * 60 * 60 * 1000;
    if (numTimeSerach < last30day) {
      Swal.fire(this.langModel.title, this.langModel.content, "warning");
    } else {
      this._cameraService
        .getCameraDisconnect(new Date(this.timeSearch).getTime())
        .subscribe(
          (res) => {
            if (res.logDisconnects.length > 0) {
              this.findAll(res.logDisconnects);
            }
          },
          (err) => {
            console.log("getCameraDisconnect " + err);
          }
        );
    }
  }
  findAll(lstIDCamDis: number[]) {
    this.isLoading = true;
    this._cameraService.findAll().subscribe(
      (res) => {
        this.isLoading = false;
        //Mở dữ liệu log cho khách hàng VTPost
        const username = JSON.parse(this.cookie.get("currentUser")).username;
        if (username == "adminvtp") {
          this.lstCameraOff = res.listCamera.map((cam) => {
            if (lstIDCamDis.includes(cam.id)) {
              return {
                id: cam.id,
                name: cam.name,
                cloudId: cam.cloudId,
                bunchName:
                  this.listBunch.find((b) => b.listCamera.includes(cam.name))
                    ?.name ?? this.langModel.title1,
                status: this.langModel.title2,
              };
            } else {
              return {
                id: cam.id,
                name: cam.name,
                cloudId: cam.cloudId,
                bunchName:
                  this.listBunch.find((b) => b.listCamera.includes(cam.name))
                    ?.name ?? this.langModel.title1,
                status: this.langModel.content1,
              };
            }
          });
        } else {
          //Không lấy dữ liệu log camera theo yêu cầu mr.Nghĩa 18/12/2024
          this.lstCameraOff = [];
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  loadListBunch() {
    this._bunchService.findAll().subscribe(
      (res) => {
        this.listBunch = res.listBunch;
        let model = new BunchModel();
        model.id = 0;
        model.name = this.langModel.title1;
        model.listCamera = [];
        this.listBunch.unshift(model);
      },
      (err) => {
        console.log("Lỗi load danh sách nhóm: " + err);
      }
    );
  }
  getFilterBunch($event: any) {
    this.titleBunch = $event.name;
    this.term = this.titleBunch;
  }
  exportExcel() {
    this._exportService.exportAsExcelFile(
      this.lstCameraOff,
      "Ket_Noi_CloudCamera_"
    );
  }
}
