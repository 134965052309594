import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-slider-solution',
  templateUrl: './slider-solution.component.html',
  styleUrls: ['./slider-solution.component.css']
})
export class SliderSolutionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
