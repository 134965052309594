import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatexPipe } from './datex.pipe';
import { SearchPipe } from './search.pipe';
import { OrderByPipe } from './order-by.pipe';
import {FilterSearchPipe} from './filterSearch.pipe';

@NgModule({
  declarations: [DatexPipe, SearchPipe, OrderByPipe,FilterSearchPipe],
  imports: [CommonModule],
  exports: [DatexPipe, SearchPipe, OrderByPipe,FilterSearchPipe],
})
export class PipeModule {}
