import { Component, Inject, OnInit } from '@angular/core';
import Chart from 'chart.js';
// import ChartDataLabels from 'chartjs-plugin-datalabels';
import { CamDisconnectByMinModel, CameraDisconnectionModel, ChartInfoModel } from 'src/app/_models/dashboard.model';
import { DashboardService } from 'src/app/_services/dashboard.service';
import { DOCUMENT } from '@angular/common';
import { Observable, interval, Subscription } from 'rxjs';
import { time } from 'console';
import { LanguageModel } from 'src/app/_models/language.model';
import { AppComponent } from 'src/app/app.component';
declare var $: any;

declare var moment: any;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  public chart: any = null;
  public topChart: any = null;
  public cameraChart: any = null;
  public packageChart: any = null;
  public accountChart: any= null;
  langModel = new LanguageModel();
  configChart: any;
  data: any;
  option: any;
  configTopChart: any;
  dataTop: any;
  optionTop: any;
  dataCamera:any;
  optionCamera: any;
  configCameraChart: any;
  dataPackage:any;
  optionPackage: any;
  configPackageChart: any;
  dataAccount:any;
  optionAccount: any;
  configAccountChart: any;
  viewDetail: boolean = true;
  listChart: CamDisconnectByMinModel[] = null;
  infoChart: ChartInfoModel = new ChartInfoModel();
  currDisConect: CameraDisconnectionModel = new CameraDisconnectionModel();
  interval: any;
  topCamDisconnects: CameraDisconnectionModel[] = null;
  timeLeft: number = 60;
  second: number = 0;
  minute: number = 0;
  hours: number = 0;
  page: number = 10;
  maxY: number = 0;
  isReadMore: boolean = false;
  isViewTable: boolean = true;
  private updateSubscription: Subscription;
  constructor(@Inject(DOCUMENT) private document: Document,private _appComponent: AppComponent, private _dashboardService: DashboardService) { }

  ngOnInit(): void {
    this.GetStatisticChart();
    this.GetTopDisconnect();
    this.GetInfoChart();
    this.startTimer(); this.updateSubscription = interval(300000).subscribe(
      (val) => {
        this.GetStatisticChart()
      }
    );
    //chuyển ngữ
    this._appComponent.translate.get('ad_count_cam').subscribe((res: string) => {
      this.langModel.title = res;
    });
    this._appComponent.translate.get('ad_count_no_connect').subscribe((res: string) => {
      this.langModel.content = res;
    });
    this._appComponent.translate.get('ad_statis_cam').subscribe((res: string) => {
      this.langModel.title1 = res;
    });
    this._appComponent.translate.get('ad_cam_bought').subscribe((res: string) => {
      this.langModel.content1 = res;
    });
    this._appComponent.translate.get('ad_cam_no_bought').subscribe((res: string) => {
      this.langModel.title2 = res;
    });
    this._appComponent.translate.get('ad_statis_pack').subscribe((res: string) => {
      this.langModel.content2 = res;
    });
    this._appComponent.translate.get('ad_count_sub_active').subscribe((res: string) => {
      this.langModel.title3 = res;
    });
    this._appComponent.translate.get('ad_count_sub_not').subscribe((res: string) => {
      this.langModel.content3 = res;
    });
    this._appComponent.translate.get('ad_statis_accont').subscribe((res: string) => {
      this.langModel.title4 = res;
    });
    this._appComponent.translate.get('ad_count_cam_dis').subscribe((res: string) => {
      this.langModel.content4 = res;
    });

    this._appComponent.translate.get('ad_top_cam_dis').subscribe((res: string) => {
      this.langModel.title5 = res;
    });
  }
  CreateDoughnutChart(){
    //Doughnut Chart about camera
    let labels =[];
    labels.push(this.langModel.title);
    labels.push(this.langModel.content);
    let dataConnects = [];
    dataConnects.push(this.infoChart.totalConnected);
    dataConnects.push(this.infoChart.totalDisconnected);
    this.dataCamera = {
      labels: labels,
      datasets: [{
        label: this.langModel.title1,
        fill: false,
        backgroundColor: ['#1865FD','#D9d8d8'],
        borderWidth: 1,
        data: dataConnects
      }]
    }
    this.option = {
      legend: {
        display: false
      },
      showTooltips: false
    }
    this.configCameraChart = {
      type: "doughnut",
      data: this.dataCamera,
      options: this.option
    }
    if (this.cameraChart != null)
      this.cameraChart.destroy();
    this.cameraChart = new Chart('cameraChart', this.configCameraChart);
    this.cameraChart.update();
    //Doughnut Chart about package
    let labels1 =[];
    labels1.push(this.langModel.content1);
    labels1.push(this.langModel.title2);
    let dataPackage = [];
    dataPackage.push(this.infoChart.totalHavePackage);
    dataPackage.push(this.infoChart.totalNoHavePackage);
    this.dataPackage = {
      labels: labels1,
      datasets: [{
        label: this.langModel.content2,
        fill: false,
        backgroundColor: ['#1865FD','#D9d8d8'],
        borderWidth: 1,
        data: dataPackage
      }]
    }
    this.option = {
      legend: {
        display: false
      },
      showTooltips: false
    }
    this.configPackageChart = {
      type: "doughnut",
      data: this.dataPackage,
      options: this.option
    }
    if (this.packageChart != null)
      this.packageChart.destroy();
    this.packageChart = new Chart('packageChart', this.configPackageChart);
    this.packageChart.update();
    //Doughnut Chart about sub account
    let labels2 =[];
    labels2.push(this.langModel.title3);
    labels2.push(this.langModel.content3);
    let dataAccounts = [];
    dataAccounts.push(this.infoChart.subAccountActive);
    dataAccounts.push(this.infoChart.subAccountDeactivate);
    this.dataAccount = {
      labels: labels2,
      datasets: [{
        label: this.langModel.title4,
        fill: false,
        backgroundColor: ['#1865FD','#D9d8d8'],
        borderWidth: 1,
        data: dataConnects
      }]
    }
    this.option = {
      legend: {
        display: false
      },
      showTooltips: false
    }
    this.configAccountChart = {
      type: "doughnut",
      data: this.dataAccount,
      options: this.option
    }
    if (this.accountChart != null)
      this.accountChart.destroy();
    this.accountChart = new Chart('accountChart', this.configAccountChart);
    this.accountChart.update();
  }
  GetMaxY(total) {
    let surplus = total % 5;
    let add = 5 - surplus;
    this.maxY = total + add;
  }
  createStatisticChart(_timeType) {
    let labeldis = [];
    let datadis = [];
    let datatotal = [];
    let dataPoint = [];
    let dataTotalPoint = [];
    this.listChart.forEach((c, index) => {
      labeldis.push(c.date);
      datadis.push(c.totalDisconnection);
      datatotal.push(c.total);
      if (index == 0 || index == this.listChart.length - 1) {
        dataPoint.push(3);
        dataTotalPoint.push(3);
      }
      else {
        //List pointRadius cam disconnect
        if (this.listChart[index].totalDisconnection != this.listChart[index - 1].totalDisconnection)
          dataPoint.push(3);
        else
          dataPoint.push(0);
        //List pointRadius total cam
        if (this.listChart[index].total != this.listChart[index - 1].total)
          dataTotalPoint.push(3);
        else
          dataTotalPoint.push(0);
      }

    });
    let maxTotal = Math.max.apply(Math, this.listChart.map(function (o) { return o.total; }));
    this.GetMaxY(maxTotal);
    this.data = {
      labels: labeldis,
      datasets: [{
        label: this.langModel.content4,
        fill: true,
        lineTension: 0,
        backgroundColor: "rgba(238, 0, 51, 0.1)",
        borderColor: "#EE0033",
        borderWidth: 0.5,
        pointRadius: 0,
        data: datadis
      },
      {
        label: 'Tổng số camera',
        fill: true,
        lineTension: 0,
        backgroundColor: "rgba(24, 101, 253, 0.1)",
        borderColor: "#1865FD",
        borderWidth: 0.8,
        pointRadius: 0,
        data: datatotal
      }
      ]
    }
    this.option = {
      legend: {
        display: false
      },
      scales: {
        xAxes: [{
          type: "time",


          time: {
            unit: "minute",
            // unitStepSize: 6,
            displayFormats: {
              day: "DD/MM",
              hour: "HH:mm",
              minute: "HH:mm",
            },
          },

          ticks: {
          }
        }],
        yAxes: [{
          display: true,
          ticks: {
            beginAtZero: true,
            precision:0,
            max: this.maxY,
            stepSize: this.maxY / 5
          }
        }]
      }
    }
    switch (_timeType) {
      case '1':
        this.option.scales.xAxes[0].time.unit = "minute";
        this.option.scales.xAxes[0].time.unitStepSize = 6;
        break;
      case '2':
        this.option.scales.xAxes[0].time.unit = "minute";
        this.option.scales.xAxes[0].time.unitStepSize = 36;
        break;
      case '3':
        this.option.scales.xAxes[0].time.unit = "hour";
        this.option.scales.xAxes[0].time.unitStepSize = 1.2;
        break;
      case '4':
        this.option.scales.xAxes[0].time.unit = "hour";
        this.option.scales.xAxes[0].time.unitStepSize = 2.4;
        this.option.scales.xAxes[0].time.displayFormats.hour = "HH:mm DD/MM";
        break;
      case '5':
        this.option.scales.xAxes[0].time.unit = "hour";
        this.option.scales.xAxes[0].time.unitStepSize = 7.2;
        this.option.scales.xAxes[0].time.displayFormats.hour = "HH:mm DD/MM";
        break;
      case '6':
        this.option.scales.xAxes[0].time.unit = "hour";
        this.option.scales.xAxes[0].time.unitStepSize = 16.8;
        this.option.scales.xAxes[0].time.displayFormats.hour = "HH:mm DD/MM";
        break;
        case '7':
          this.option.scales.xAxes[0].time.unit = "day";
          this.option.scales.xAxes[0].time.unitStepSize = 3;
          this.option.scales.xAxes[0].time.displayFormats.day = "DD/MM";
          break;
      default:
        break;
    }

    this.configChart = {
      type: "line",
      data: this.data,
      options: this.option
    }
    if (this.chart != null)
      this.chart.destroy();
    this.chart = new Chart("connectCamChart", this.configChart);
  }
  GetStatisticChart() {
    let timeType = $('#slTimeType').val();
    let d = new Date();
    let d1 = new Date();
    switch (timeType) {
      case '1':
        d1.setHours(d1.getHours() - 1);
        break;
      case '2':
        d1.setHours(d1.getHours() - 6);
        break;
      case '3':
        d1.setHours(d1.getHours() - 12);
        break;
      case '4':
        d1.setDate(d1.getDate() - 1);
        break;
      case '5':
        d1.setDate(d1.getDate() - 3);
        break;
      case '6':
        d1.setDate(d1.getDate() - 7);
        break;
        case '7':
          d1.setDate(d1.getDate() - 30);
          break;
      default:
        d1.setHours(d1.getHours() - 12);
        break;
    }
    let endTime = d.getTime();
    let startTime = d1.getTime();
    this._dashboardService.getChartDisconnect(startTime, endTime).subscribe(
      res => {
        this.listChart = res.data;
        this.createStatisticChart(timeType);
      },
      error => {
        console.log(error);
      }
    );
  }
  GetInfoChart() {
    this._dashboardService.getInfoChart().subscribe(
      res => {
        this.infoChart = res;
        this.CreateDoughnutChart();
      },
      error => {
      }
    );
  }
  CreateTopChart() {
    let labels = [];
    let dataTopDis = [];
    let ids = [];

    this.topCamDisconnects.forEach(c => {
      labels.push(c.name);
      dataTopDis.push(c.number);
      ids.push(c.id);
    });
    this.dataTop = {
      labels: labels,
      datasets: [{
        label: this.langModel.title5,
        fill: false,
        lineTension: 0,
        backgroundColor: "#EE0033",
        borderColor: "#EE0033",
        borderWiidth: 1,
        barPercentage: 0.6,
        pointRadius: 0,
        data: dataTopDis,
        id: ids
      }]
    }
    this.option = {
      legend: {
        display: false
      },
      scales: {
        scales: {
          xAxes: {
            ticks: {
              callback: function(value) {
                const label = this.getLabelForValue(value);
                return label.length > 15 ? label.slice(0, 15) + '...' : label; // giới hạn 15 ký tự
              }
            }
          }
        // yAxes: [{
        //   display: true,
        //   ticks: {
        //     // max: Math.max(...this.data.datasets[0].data + 10),
        //     precision:0,
        //     beginAtZero: true
        //   }
        // }]
        }
      },

      showTooltips: false,
      onClick: event => {
        let point = Chart.helpers.getRelativePosition(event, this.topChart.chart);
        let xIndex = this.topChart.scales['x-axis-0'].getValueForPixel(point.x);
        let id = this.topChart.data.datasets[0].id[xIndex];
        this.topCamDisconnects.forEach(c => {
          if (c.id == id) {
            this.currDisConect = c;
          }
        });
        this.isViewTable = true;
      },
    }
    this.configTopChart = {
      type: "bar",
      data: this.dataTop,
      options: this.option
    }
    if (this.topChart != null)
      this.topChart.destroy();
    this.topChart = new Chart('topDisConnectChart', this.configTopChart);
    this.topChart.update();
  }
  startTimer() {
    this.second = this.minute = this.hours = 0;
    this.interval = setInterval(() => {
      if (this.second == 60) {
        this.minute++;
        if (this.minute == 60) {
          this.hours++
          this.minute = 0;
        }
        this.second = 0;
      }
      this.second++;
    }, 1000)
  }
  GetTopDisconnect() {
    let timeType2 = $('#slTimeType2').val();
    let d = new Date();
    let d1 = new Date();
    switch (timeType2) {
      case '1':
        d1.setHours(d1.getHours() - 1);
        break;
      case '2':
        d1.setHours(d1.getHours() - 6);
        break;
      case '3':
        d1.setHours(d1.getHours() - 12);
        break;
      case '4':
        d1.setDate(d1.getDate() - 1);
        break;
      case '5':
        d1.setDate(d1.getDate() - 3);
        break;
      case '6':
        d1.setDate(d1.getDate() - 7);
        break;
        case '7':
          d1.setDate(d1.getDate() - 30);
          break;
      default:
        d1.setHours(d1.getHours() - 1);
        break;
    }
    let endTime = d.getTime();
    let startTime = d1.getTime();
    this.isViewTable = false;
    this._dashboardService.getTopDisconnect(startTime, endTime).subscribe(
      res => {
        this.topCamDisconnects = res.topCameraDisconnection;
        this.CreateTopChart();
      },
      error => {
      }
    );
  }
  ReadMore() {
    this.page = this.currDisConect.detail.length;
    this.isReadMore = true;
  }
  HideOff() {
    this.page = 10;
    this.isReadMore = false;
  }
  CloseStatistic() {
    this.isViewTable = false;
  }
}
