import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { AuthHepler } from "src/app/_helpers/auth.helper";
import {
  AccountModel,
  BunchModel,
  CameraModel,
  UsernameCameraIdsModel,
} from "src/app/_models";
import {
  AccountService,
  AuthenticationService,
  CopyPermissionService,
} from "src/app/_services";
import { TreeviewService } from "src/app/_services/treeview.service";
import { AppComponent } from "src/app/app.component";
import Swal from "sweetalert2";
declare var $: any;
@Component({
  selector: "app-share-permission",
  templateUrl: "./share-permission.component.html",
  styleUrls: ["./share-permission.component.css"],
})
export class SharePermissionComponent extends AuthHepler implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private _treeService: TreeviewService,
    private _accService: AccountService,
    private _copyPerService: CopyPermissionService,
    private _router: Router,
    public _authenticationService: AuthenticationService,
    public cookie: CookieService,
    private renderer: Renderer2,
    private el: ElementRef,
    private _appComponent: AppComponent
  ) {
    super(_authenticationService, _authenticationService, cookie);
  }
  // selectedBunch: BunchModel = null;
  isView: boolean = false;
  isPermiss: boolean = true;
  isChooseUser: boolean = true;
  lstUser: AccountModel[] = [];
  lstSubAccount: AccountModel[] = [];
  bunchList: BunchModel[];
  cameraNoBunchs: CameraModel[];
  bunchCList: BunchModel[];
  cameraAllows: CameraModel[];
  currIdCamAlloweds: number[] = [];
  idCamCopyAlloweds: number[] = [];
  currUsername: string = "";
  keyBunch: string = "";
  keySubAccount: string = "";
  usernameCopy: string = "";
  keyCBunch: string = "";
  keyCCamera: string = "";
  roleId: string = "";
  accSelected: AccountModel = new AccountModel();
  translations: { [key: string]: string } = {};
  private _keyCamera: string = "";
  get keyCamera(): string {
    return this._keyCamera;
  }

  set keyCamera(value: string) {
    this._keyCamera = value;
    this.updateIsCheckedAllCam(); // Gọi hàm khi giá trị thay đổi
  }




  @ViewChildren("filteredListCam") filteredListCam!: QueryList<ElementRef>;
  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.currUsername = params["username"];
      this.roleId = params["roleId"];
    });
    this.getCameraAllows();
    this.getListSubAccount();
    if (this.roleId == "2") {
      this.isView = true;
    } else this.isView = false;
    this.updateActiveClass();
  }
  ngAfterViewInit() {
    this.filteredListCam.changes.subscribe(() => {
      this.handleFilteredList();
    });
  }
  handleFilteredList() {
    const filteredCameras = this.filteredListCam.map(
      (ref) => ref.nativeElement
    );
    console.log("Filtered Cameras:", filteredCameras);
    this.updateIsCheckedAllCam();
  }
  updateActiveClass() {
    const div = this.el.nativeElement.querySelector("#iAccount");
    this.renderer.addClass(div, "active");
  }
  //#region Phân quyền camera
  isCheckedAllGroup: boolean = true;
  onSelectAllGroupChange(event: any) {
    const isChecked = event.target.checked;
    this.bunchList.forEach((bunch: any) => {
      bunch.checked = isChecked;
    });
    this.cameraAllows.map((cam: any) => {
      cam.isView = cam.originView = isChecked;
    });
  }
  onCheckboxBunchChange(event: any) {
    //Xử lý checkbox all bunch
    this.isCheckedAllGroup = this.areAllGroupChecked();
    const bunchId = +(event.target as HTMLInputElement).id.replace(
      "bunch_",
      ""
    );
    const isView = event.target.checked;
    //Xử lý ẩn/hiện camera trong 1 nhóm
    if (bunchId != 0) this.filterCamInBunch(bunchId, isView);
    else {
      this.cameraNoBunchs.forEach((camInBunch) => {
        const camAllow = this.cameraAllows.find(
          (camAllow) => camAllow.id === camInBunch.id
        );
        if (camAllow) {
          camAllow.isView = camAllow.originView = isView;
        }
      });
    }
  }
  filterCamInBunch(_bunchId: number, isView: boolean) {
    this._treeService.getCameraInBunch(_bunchId).subscribe(
      (res) => {
        res.listCamera.forEach((camInBunch) => {
          const camAllow = this.cameraAllows.find(
            (camAllow) => camAllow.id === camInBunch.id
          );
          if (camAllow) {
            camAllow.isView = isView;
          }
        });
      },
      (err) => {
        let errorBody = JSON.parse(err._body || "{}");
        if (errorBody.code === 401) {
          this.unAuthHandle(() => this.filterCamInBunch(_bunchId, isView));
        } else {
          console.log("Lỗi function: filterCamInBunch " + err);
        }
      }
    );
  }
  areAllGroupChecked(): boolean {
    for (let b of this.bunchList) {
      if (b.checked == undefined || b.checked == false) return false;
    }
    return true;
  }
  //Xử lý checkbox camera
  isCheckedAllCam: boolean = false;
  onSelectAllChange(event: any) {
    //Lây danh sách camera sau khi search
    const isChecked = event.target.checked;
    this.cameraAllows.forEach((cam: any) => {
      if (cam.isView == true) {
        cam.checked = isChecked;
      }
    });
  }
  updateIsCheckedAllCam() {
    const camerasToCheck = this.cameraAllows.filter((cam: any) => cam.isView);
    if (camerasToCheck.length > 0) {
      if (camerasToCheck.every((cam: any) => cam.checked)) {
        // Nếu tất cả các camera có `isView = true` và `checked = true`
        this.isCheckedAllCam = true;
      } else {
        // Nếu tồn tại camera có `isView = true` và `checked = false`
        this.isCheckedAllCam = false;
      }
    } else this.isCheckedAllCam = false;
  }
  onCheckboxChange(event: any) {
    this.isCheckedAllCam = this.areAllChecked();
  }
  areAllChecked(): boolean {
    for (let c of this.cameraAllows) {
      if (c.checked == undefined || c.checked == false) return false;
    }
    return true;
  }
  getListBunch() {
    this._treeService.getCameraTree().subscribe(
      (res) => {
        this.bunchList = res.listBunch.map((bunch: any) => ({
          ...bunch,
          checked: true,
        }));
        this.bunchCList = res.listBunch.map((bunch: any) => ({
          ...bunch,
          checked: true,
        }));
        if (res.listCamera.length > 0) {
          this.cameraNoBunchs = [];
          for (let cam of res.listCamera) {
            let camAllow = this.cameraAllows.find(
              (camAllow) => camAllow.id === cam.id
            );
            if (camAllow) {
              this.cameraNoBunchs.push(camAllow);
            }
          }
          if (this.cameraNoBunchs.length > 0) {
            var bunch = new BunchModel();
            bunch.name = this.t("li_not_bunch");
            bunch.id = 0;
            bunch.listCamera = this.cameraNoBunchs;
            this.bunchList.push(bunch);
            this.bunchCList.push(bunch);
          }
        }
      },
      (err) => {
        let errorBody = JSON.parse(err._body || "{}");
        if (errorBody.code === 401) {
          this.unAuthHandle(() => this.getListBunch());
        } else {
          console.log("Lỗi function: getListBunch " + err);
        }
      }
    );
  }
  getCameraAllows() {
    this._accService.listCameraAllowAsign().subscribe(
      (res) => {
        this.cameraAllows = res.listCamera.map((cam: any) => ({
          ...cam,
          isView: true,
          originView:true
        }));
        this.getCamerasAssigned();
        this.getListBunch();
      },
      (err) => {
        let errorBody = JSON.parse(err._body || "{}");
        if (errorBody.code === 401) {
          this.unAuthHandle(() => this.getCameraAllows());
        } else {
          console.log("Lỗi function: getCameraAllows " + err);
        }
      }
    );
  }
  getCamerasInBunch(_bunch: BunchModel) {
    // this.selectedBunch = _bunch;
    if (_bunch.id !== 0) {
      this._treeService.getCameraInBunch(_bunch.id).subscribe(
        (res) => {
          _bunch.listCamera = [];
          //check list cam đc quyền gán có trong nhóm này thì push vào
          for (let cam of res.listCamera) {
            const camAllow = this.cameraAllows.find(
              (camAllow) => camAllow.id === cam.id
            );
            if (camAllow) {
              _bunch.listCamera.push(camAllow);
            }
          }
        },
        (err) => {
          let errorBody = JSON.parse(err._body || "{}");
          if (errorBody.code === 401) {
            this.unAuthHandle(() => this.getCamerasInBunch(_bunch));
          } else {
            console.log("Lỗi function: getCameraInBunch " + err);
          }
        }
      );
    }
  }
  getCamerasAssigned() {
    //reset checked
    this.cameraAllows.forEach((c) => (c.checked = false));
    let username: string = "";
    if (this.isPermiss) {
      username = this.currUsername;
      if (this.roleId == "2") this.isView = true;
      else this.isView = false;
    } else {
      username = this.accSelected.username;
      if (this.accSelected?.role?.id == 2) this.isView = true;
      else this.isView = false;
    }

    this._accService.listCameraAssign(username).subscribe(
      (res) => {
        if (this.isPermiss) {
          //Lấy list id của user được gán quyền phục vụ sao chép
          this.currIdCamAlloweds = [];
          res.listCamera.forEach((camAssin) => {
            let camAllowed = this.cameraAllows.find(
              (camAllowed) => camAllowed.id === camAssin.id
            );
            if (camAllowed) {
              this.currIdCamAlloweds.push(camAllowed.id);
              camAllowed.checked = true;
            }
          });
          //Màn phân quyền khi camera đc phân hết thì checked checkAll
          if (this.currIdCamAlloweds.length == this.cameraAllows.length)
            this.isCheckedAllCam = true;
        } else {
          this.idCamCopyAlloweds = [];
          res.listCamera.forEach((camAssin) => {
            let camAllowed = this.cameraAllows.find(
              (camAllowed) => camAllowed.id === camAssin.id
            );
            if (camAllowed) {
              this.idCamCopyAlloweds.push(camAllowed.id);
              camAllowed.checked = true;
            }
          });
          //Màn sao chép quyền khi camera đc phân hết thì checked checkAll
          if (this.idCamCopyAlloweds.length == this.cameraAllows.length)
            this.isCheckedAllCam = true;
        }
        this.cameraAllows.sort((a, b) => {
          if (b.checked !== a.checked) {
            return b.checked ? 1 : -1;
          }
          return a.name.localeCompare(b.name);
        });
      },
      (err) => {
        let errorBody = JSON.parse(err._body || "{}");
        if (errorBody.code === 401) {
          this.unAuthHandle(() => this.getCamerasAssigned());
        } else {
          console.log("Lỗi function: getCamerasAssigned " + err);
        }
      }
    );
  }
  //#endregion
  //#region Lưu phân quyền
  cameraCount: number = 0;
  totalCamera: number = 0;
  strListCamera: string = "";
  savePermission() {
    let idRole = "2";
    if (this.isView == true) idRole = "2";
    else idRole = "3";
    let lstCameraChecked = this.cameraAllows
      .filter((c) => c.checked === true)
      .map((ac) => ac.id);
    this.totalCamera = lstCameraChecked.length;
    if (this.isPermiss == true) {
      this._accService
        .UpdateConfig(this.currUsername, idRole, lstCameraChecked)
        .subscribe(
          (res) => {
            Swal.fire(
              this.t("role_role"),
              this.t("role_content_success"),
              "success"
            ).then(() => this._router.navigate(["/account"]));
          },
          (err) => {
            let errorBody = JSON.parse(err._body || "{}");
            if (errorBody.code === 401) {
              this.unAuthHandle(() => this.savePermission());
            } else if (errorBody.code == 402) {
              this.showModalLimitCamera(errorBody.message);
            } else {
              Swal.fire(
                this.t("role_role"),
                this.t("role_error") + err,
                "error"
              );
            }
          }
        );
    } else {
      //Lưu sao chép quyền
      if (this.accSelected.id == null) {
        Swal.fire(
          this.t("role_copy_user_permission"),
          this.t("role_chooseuser"),
          "warning"
        );
      } else {
        let strRole = this.t("role_view");
        if (this.isView == false) strRole = this.t("role_manage");
        strRole += lstCameraChecked.length.toString();
        Swal.fire({
          title: this.t("role_copy_user_permission"),
          html:
            '<div style="text-align: left; font-size: 16px;"><b>' +
            this.t("role_confirm_copy") +
            strRole +
            this.t("role_confirm1") +
            '<span style="color:#e03">' +
            this.accSelected.username +
            "</span>" +
            this.t("role_confirm2") +
            '<span style="color:#e03">' +
            this.currUsername +
            "</span></b>" +
            '<label style="margin-top: 15px; font-weight:bold;"><input type="checkbox" style="cursor: pointer;margin: 5px; transform: scale(1.4);" id="move-checkbox"/>' +
            this.t("role_confirm_del") +
            '<span style="color:#e03">' +
            this.accSelected.username +
            "</span>" +
            this.t("role_confirm_del1") +
            "</label></div>",
          showCancelButton: true,
          confirmButtonColor: "#E03",
          confirmButtonText: "OK",
          cancelButtonText: this.t("li_cancel"),
        }).then((result) => {
          if (result.value) {
            const chkMove = document.getElementById(
              "move-checkbox"
            ) as HTMLInputElement;
            let model = new UsernameCameraIdsModel();
            model.username = this.accSelected.username;
            //chỉ thực hiện remove những camera nào check
            model.listCameraId = lstCameraChecked;
            //Extend list camera của subacc hiện tại + list cam của subacc mới
            let listIdExtend = Array.from(
              new Set(this.currIdCamAlloweds.concat(lstCameraChecked))
            );
            if (chkMove.checked == true) {
              //Thực hiện xóa ở tài khoản cũ, chuyển sang tài khoản mới
              this._copyPerService.removeListByUsername(model).subscribe(
                (res) => {
                  this._accService
                    .UpdateConfig(this.currUsername, idRole, listIdExtend)
                    .subscribe(
                      (res1) => {
                        Swal.fire({
                          title: this.t("role_trans"),
                          icon: "success",
                          html:
                            '<div style="text-align: left; font-size: 16px;"><b>' +
                            this.t("role_trans1") +
                            '<span style="color:#e03">' +
                            this.accSelected.username +
                            "</span>" +
                            this.t("role_trans2") +
                            '<span style="color:#e03">' +
                            this.currUsername +
                            "</span>" +
                            this.t("role_trans3") +
                            "</span></b></div>",
                          confirmButtonText: "OK",
                        });
                        this._router.navigate(["/account"]);
                      },
                      (err1) => {
                        let errorBody = JSON.parse(err1._body || "{}");
                        if (errorBody.code === 401) {
                          this.unAuthHandle(() => this.savePermission());
                        } else if (errorBody.code == 402) {
                          this.showModalLimitCamera(errorBody.message);
                        } else {
                          Swal.fire(
                            this.t("role_trans"),
                            this.t("role_trans_err") + errorBody.message,
                            "error"
                          );
                        }
                      }
                    );
                },
                (err) => {
                  let errorBody = JSON.parse(err._body || "{}");
                  if (errorBody.code === 401) {
                    this.unAuthHandle(() => this.savePermission());
                  } else {
                    console.log("Lỗi xóa khi chuyển quyền: " + err);
                  }
                }
              );
            } else {
              //Sao chép sang tài khoản mới
              this._accService
                .UpdateConfig(this.currUsername, idRole, listIdExtend)
                .subscribe(
                  (res1) => {
                    Swal.fire({
                      title: this.t("role_copy_user_permission"),
                      icon: "success",
                      html:
                        '<div style="text-align: left; font-size: 16px;"><b>' +
                        this.t("role_copy1") +
                        strRole +
                        this.t("role_copy2") +
                        '<span style="color:#e03">' +
                        this.accSelected.username +
                        "</span>" +
                        this.t("role_trans2") +
                        '<span style="color:#e03">' +
                        this.currUsername +
                        "</span>" +
                        this.t("role_trans3") +
                        "</span></b></div>",
                      confirmButtonText: "OK",
                    });
                    this._router.navigate(["/account"]);
                  },
                  (err1) => {
                    let errorBody = JSON.parse(err1._body || "{}");
                    if (errorBody.code === 401) {
                      this.unAuthHandle(() => this.savePermission());
                    } else if (errorBody.code === 402) {
                      this.showModalLimitCamera(errorBody.message);
                    } else {
                      console.log(
                        "Lỗi khi thực hiện sao chép quyền cho người mới: " +
                          err1
                      );
                    }
                  }
                );
            }
          } else if (result.dismiss) {
            return;
          }
        });
      }
    }
  }
  //#endregion
  //#region Sao chép quyền
  getListSubAccount() {
    this._accService.findAll().subscribe(
      (res) => {
        this.lstSubAccount = res.listAccount.filter(
          (c) => c.username !== this.currUsername && c.status !== "PENDING"
        );
      },
      (error) => {
        let errorBody = JSON.parse(error._body || "{}");
        if (errorBody.code === 401) {
          this.unAuthHandle(() => this.getListSubAccount());
        } else {
          console.log(
            "Lỗi lấy danh sách người dùng: getListSubAccount: " + error
          );
        }
      }
    );
  }
  isCheckedAllCGroup: boolean = true;
  onSelectAllCGroupChange(event: any) {
    const isChecked = event.target.checked;
    this.bunchCList.forEach((bunch: any) => {
      bunch.checked = isChecked;
    });
    this.cameraAllows.map((cam: any) => {
      cam.isView = isChecked;
    });
  }
  onCheckboxBunchCChange(event: any) {
    //Xử lý checkbox all bunch
    this.isCheckedAllCGroup = this.areAllGroupCChecked();
    const bunchId = +(event.target as HTMLInputElement).id.replace(
      "bunch_",
      ""
    );
    const isView = event.target.checked;
    //Xử lý ẩn/hiện camera trong 1 nhóm
    if (bunchId != 0) this.filterCamInBunch(bunchId, isView);
    else {
      this.cameraNoBunchs.forEach((camInBunch) => {
        const camAllow = this.cameraAllows.find(
          (camAllow) => camAllow.id === camInBunch.id
        );
        if (camAllow) {
          camAllow.isView = isView;
        }
      });
    }
  }
  areAllGroupCChecked(): boolean {
    for (let b of this.bunchCList) {
      if (b.checked == undefined || b.checked == false) return false;
    }
    return true;
  }
  countCameras(str: string): number {
    // Tách chuỗi dựa trên dấu phẩy và loại bỏ khoảng trắng
    const cameras = str
      .split(",")
      .map((item) => item.trim()) // Loại bỏ khoảng trắng ở đầu và cuối mỗi phần tử
      .filter((item) => item.length > 0); // Loại bỏ các phần tử rỗng nếu có

    // Đếm số lượng phần tử
    return cameras.length;
  }
  exportToFile() {
    // Tạo đối tượng Blob với nội dung chuỗi
    const blob = new Blob([this.strListCamera], {
      type: "text/plain;charset=utf-8",
    });

    // Tạo URL cho đối tượng Blob
    const url = URL.createObjectURL(blob);

    // Tạo một thẻ <a> và kích hoạt sự kiện click để tải tệp
    const a = document.createElement("a");
    a.href = url;
    a.download = "list-camera-limit.txt"; // Tên tệp tải về
    document.body.appendChild(a);
    a.click();

    // Giải phóng URL
    URL.revokeObjectURL(url);

    // Xóa thẻ <a> khỏi DOM
    document.body.removeChild(a);
  }
  showModalLimitCamera(_mess: string) {
    if (_mess != "") {
      this.strListCamera =
        this.t("role_list_limit") + _mess.trim().replace(/,\s*$/, "");
      this.cameraCount = this.countCameras(_mess);
    }
    $("#shareCameraLimit")
      .modal({ backdrop: "static", keyboard: false })
      .modal("show");
  }
  t(_key): string {
    return this._appComponent.translate.instant(_key);
  }
  //#endregion
}
