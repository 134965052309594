import * as _ from 'lodash';
import { Component, TemplateRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { MessagingService } from './_services';
import { NotificationsService } from 'angular2-notifications';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  @ViewChild('alertRef') alertRef: TemplateRef<any>;

  onActivate(event) {
    window.scroll(0, 0);
  }

  constructor(
    private router: Router,
    public translate: TranslateService,
    private cookie: CookieService,
    private messagingService: MessagingService,
    private notificationService: NotificationsService,
  ) {
    var defLang = this.cookie.get('language');
    if (defLang == null || defLang == '') {
      translate.setDefaultLang('vi');
      translate.use('vi');
      this.cookie.set('language', 'vi');
    } else {
      translate.setDefaultLang(defLang);
      translate.use(defLang);
    }

    this.messagingService.requestPermission();
    this.messagingService.receiveMessages();
    this.messagingService.currentMessage.subscribe((message) => {
      if (message) {
        const title = message['notification']['title'];
        const body = message['notification']['body'];
        const data = message['data'];
        const context = { title: title, body: body, data: data };
        this.notificationService.html(this.alertRef, null, null, null, context);
      }
    });
  }

  onNotificationClick(data) {
    const read  = _.get(data, 'gcm.notification.id');
    this.router.navigate(['aicamera', 'smart-alerts'], { queryParams: { read } });
  }

  switchLanguage(language: string) {
    this.translate.setDefaultLang(language);
    this.translate.use(language);
  }
  translateText(key: string) {
    return this.translate.instant(key);
  }
}
